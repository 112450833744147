<template>
    <div>
        <b-row>
            <b-col cols="8">
                <div class="mb-2">
                    <h4>Filtre</h4>
                </div>
            </b-col>
            <b-col lg="4" md="4" class="text-right">
                <b-button variant="primary" @click="cleanFilters">
                    Netejar filtres
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Cia" label-for="Cia">
                    <b-form-input v-model="searchFields.company" placeholder="Cia" autocomplete="off"
                                  @update="searchPolicies()"/>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Pòlissa" label-for="Pòlissa">
                    <b-form-input v-model="searchFields.policy_number" placeholder="Pòlissa" autocomplete="off"
                                  @update="searchPolicies()"/>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12">
                <b-form-group label="Ram" label-for="Ram">
                    <select v-model="searchFields.insurance_type" class="custom-select" @change="searchPolicies()">
                        <option value=""></option>
                        <option v-for="type in insurancesTypes"
                                :key="type.value"
                                :value="type.value"> {{ type.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Risc" label-for="Risc">
                    <b-form-input v-model="searchFields.risk" placeholder="Risc" autocomplete="off"
                                  @update="searchPolicies()"/>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Primer efecte" label-for="Primer efecte">
                    <flat-pickr
                        v-model="searchFields.date_first_effect"
                        class="form-control"
                        placeholder="Primer efecte"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchPolicies()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Data efecte" label-for="Data efecte">
                    <flat-pickr
                        v-model="searchFields.date_effect"
                        class="form-control"
                        placeholder="Data efecte"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchPolicies()"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Data venciment" label-for="Data venciment">
                    <flat-pickr
                        v-model="searchFields.date_expiration"
                        class="form-control"
                        placeholder="Data venciment"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchPolicies()"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="!this.$route.params.id" cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Client" label-for="Client">
                    <b-form-input v-model="searchFields.client" placeholder="Client" autocomplete="off"
                                  @update="searchPolicies()"/>
                </b-form-group>
            </b-col>

            <b-col lg="2" md="12">
                <b-form-group label="Valor pòlissa" label-for="Valor pòlissa">
                    <b-form-input v-model="searchFields.value" placeholder="Valor pòlissa" autocomplete="off"
                                  @update="searchPolicies()"/>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12">
                <b-form-group label="Període" label-for="Període">
                    <select v-model="searchFields.period" class="custom-select" @change="searchPolicies()">
                        <option value=""></option>
                        <option v-for="period in policiesPeriods"
                                :key="period.value"
                                :value="period.value"> {{ period.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12">
                <b-form-group label="Any" label-for="Any">
                    <b-form-input v-model="searchFields.year" placeholder="Any" autocomplete="off"
                                  @update="searchPolicies()"/>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12">
                <b-form-group label="Estat" label-for="Estat">
                    <select v-model="searchFields.status" class="custom-select"
                            :class="'background-' + searchFields.status" @change="searchPolicies()">
                        <option value=""></option>
                        <option v-for="status in policiesStatus"
                                :key="status.value"
                                :value="status.value"> {{ status.name.toUpperCase() }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup,
    BIcon,
    BInputGroupText,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import TablePolicies from "@/views/insurance/policies/list/components/tablePolicies"
import CreatePolicyButton from "@/views/insurance/policies/list/components/createPolicyButton"
import {createHelpers} from "vuex-map-fields"
import {policiesStatus} from "@/constants/insurancePolicies"
import {policiesPeriods, insurancesTypes} from "@/constants/insurancePolicies"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"

const {mapFields} = createHelpers({
    getterType: 'insurancePolicies/getField',
    mutationType: 'insurancePolicies/updateField',
})

export default {
    name: "FilterPolicies",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        BIcon,
        BInputGroupText,

        vSelect,
        flatPickr,

        TablePolicies,
        CreatePolicyButton

    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            showLoading: false,
            searchFields: [],
            searchConfig: [],

            policiesStatus,
            policiesPeriods,
            insurancesTypes,

            CatalanLocale
        }
    },
    mounted() {
        this.$store.commit('insurancePolicies/RESET_STATE')
        this.getPolicies()
    },
    methods: {
        searchPolicies() {
            this.getPolicies()
        },
        cleanFilters() {
            this.searchFields = []
            this.getPolicies()
        },
        getPolicies() {
            this.showLoading = true
            this.searchConfig = [
                {'type': 'string', 'column': 'company', 'searchTerm': this.searchFields.company ?? ''},
                {'type': 'string', 'column': 'policy_number', 'searchTerm': this.searchFields.policy_number ?? ''},
                {'type': 'string', 'column': 'insurance_type', 'searchTerm': this.searchFields.insurance_type ?? ''},
                {'type': 'string', 'column': 'risk', 'searchTerm': this.searchFields.risk ?? ''},
                {
                    'type': 'string',
                    'column': 'date_first_effect',
                    'searchTerm': this.searchFields.year ?? ''
                },
                {'type': 'date', 'column': 'date_effect', 'searchTerm': this.searchFields.date_effect ?? ''},
                {'type': 'date', 'column': 'date_expiration', 'searchTerm': this.searchFields.date_expiration ?? ''},
                {
                    'type': 'concat_strings',
                    'concat_fields': ['clients.name', 'clients.first_surname', 'clients.second_surname'],
                    'searchTerm': this.searchFields.client ?? ''
                },
                {'type': 'string', 'column': 'value', 'searchTerm': this.searchFields.value ?? ''},
                {'type': 'string', 'column': 'period', 'searchTerm': this.searchFields.period ?? ''},
                {'type': 'date', 'column': 'date_payment', 'searchTerm': this.searchFields.date_payment ?? ''},
                {'type': 'string', 'column': 'status', 'searchTerm': this.searchFields.status ?? ''}
            ]

            this.$store.dispatch('insurancePolicies/searchPolicies', {
                client_id: this.$route.params.id ?? '',
                per_page: this.pageLength,
                page: this.page,
                filter: this.searchConfig.length > 0 ? this.searchConfig : '',
                relations: ['client']
            })
            .then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records
                this.$emit('update',this.totalRecords)
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.background-in_progress {
    color: #ffc107;
    border: 1px solid #ffc107;
}

.background-accepted {
    color: #28c76f;
    border: 1px solid #28c76f;
}

.background-sent_to_client {
    color: #00cfe8;
    border: 1px solid #00cfe8;
}

.background-anulled {
    color: #82868b;
    border: 1px solid #82868b;
}

.background-denied {
    color: #ea5455;
    border: 1px solid #ea5455;
}

select option[value="in_progress"] {
    color: #ffc107 !important;
    background: rgba(255, 159, 67, 0.12);
}

select option[value="accepted"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}

select option[value="sent_to_client"] {
    color: #00cfe8 !important;
    background: rgba(0, 207, 232, 0.12);
}

select option[value="anulled"] {
    color: #82868b !important;
    background: rgba(130, 134, 139, 0.12);
}

select option[value="denied"] {
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.12);
}
</style>