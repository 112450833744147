<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <b-tabs align="left" @activate-tab="tabChanged">
                        <b-tab title="Informació general" lazy>
                            <div class="mb-3">
                                <form-data-client :client="client"/>

                                <b-row align-h="start">
                                    <b-col cols="5">
                                        <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="sendData">
                                            Desar canvis
                                        </b-button>
                                        <b-button v-else variant="primary" class="mt-2 mr-1" @click="updateData">
                                            Actualitzar canvis
                                        </b-button>
                                        <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                            Cancel·lar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-tab>
                        <b-tab :active="activeTab === 'documents'" :title-item-class="showTabDocuments"
                               title="Documents" lazy>
                            <form-documents v-if="client.id" :getClientParentComponent="getClient"/>
                        </b-tab>
                        <b-tab :active="activeTab === 'policies'" :title-item-class="showTabPolicies"
                               title="Pòlisses" lazy>
                            <list-policies-client v-if="client.id && client.insurance_policies.length > 0"/>
                        </b-tab>
                        <b-tab :active="activeTab === 'inversions'" :title-item-class="showTabInversions"
                               title="Inversions" lazy>
                            <list-investments-client v-if="client.id && client.investments.length > 0"/>
                        </b-tab>
                        <b-tab :active="activeTab === 'financings'" :title-item-class="showTabFinancing"
                               title="Finançaments" lazy>
                            <list-financings-client v-if="client.id && client.financings.length > 0"/>
                        </b-tab>
                        <b-tab :active="activeTab === 'requirements'" :title-item-class="showTabRequirements"
                               title="Requeriments" lazy>
                            <list-requirements-client v-if="client.id && client.requirements.length > 0"/>
                        </b-tab>
                        <b-tab :active="activeTab === 'scriptures'" :title-item-class="showTabScriptures"
                               title="Escripturas" lazy>
                            <list-scriptures-client v-if="client.id && client.scriptures.length > 0"/>
                        </b-tab>
                        <b-tab :active="activeTab === 'inheritances'" :title-item-class="showTabInheritances"
                               title="Herencies" lazy>
                            <list-inheritances-client v-if="client.id && client.inheritances.length > 0"/>
                        </b-tab>
                        <b-tab :active="activeTab === 'tradings'" :title-item-class="showTabTradings"
                               title="Mercantil" lazy>
                            <list-tradings-client v-if="client.id && client.tradings.length > 0"/>
                        </b-tab>
                        <b-tab :active="activeTab === 'tradings'" :title-item-class="showTabFormalities"
                               title="Laboral" lazy>
                            <list-formalities-client v-if="client.id && client.formalities.length > 0"/>
                        </b-tab>
                    </b-tabs>
                </b-overlay>
            </b-card>
        </b-col>

        <b-col sm="12">
            <form-data-filter-view v-if="num_tab==0" :client="client"/>
        </b-col>
    </b-row>
</template>

<script>
import { BButton, BCard, BCol, BOverlay, BRow, BTab, BTabs } from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {CLIENTS_LIST} from "@/router/web/constants"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import {mapState} from "vuex"
import FormDataClient from "@/views/clients/form/formDataClientView"
import FormDataFilterView from "@/views/clients/form/formDataFilterView"
import FormDocuments from "@/views/clients/form/components/formDocumentsView"

import {createHelpers} from "vuex-map-fields"
import ListPoliciesClient from "@/views/clients/list/components/listPoliciesView"
import ListInvestmentsClient from "@/views/clients/list/components/listInvestmentsView"
import ListFinancingsClient from "@/views/clients/list/components/listFinancingsView"
import ListRequirementsClient from "@/views/clients/list/components/listRequirementsView"
import ListScripturesClient from "@/views/clients/list/components/listScripturesView"
import ListInheritancesClient from "@/views/clients/list/components/listInheritancesView"
import ListTradingsClient from "@/views/clients/list/components/listTradingsView"
import ListFormalitiesClient from "@/views/clients/list/components/listFormalitiesView"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "FormView",
    components: {
        ListScripturesClient,
        ListPoliciesClient,
        ListInvestmentsClient,
        ListFinancingsClient,
        ListRequirementsClient,
        ListInheritancesClient,
        ListTradingsClient,
        ListFormalitiesClient,
        BCol,
        BRow,
        BButton,
        BCard,
        BOverlay,
        BTab,
        BTabs,
        FormDataClient,
        FormDataFilterView,
        FormDocuments,
    },
    props: ['activeTab'],
    data() {
        return {
            users: [],
            num_tab: 0,
            showLoading: false,
            SpanishLocale,
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client: 'client',
            edit: 'edit',
        }),
        showTabFinancing: function () {
            return this.client.id > 0 && this.client.financings.length > 0 ? '' : 'd-none'
        },
        showTabPolicies: function () {
            return this.client.id > 0 && this.client.insurance_policies.length > 0 ? '' : 'd-none'
        },
        showTabInversions: function () {
            return this.client.id > 0 && this.client.investments.length > 0 ? '' : 'd-none'
        },
        showTabRequirements: function () {
            return this.client.id > 0 && this.client.requirements.length > 0 ? '' : 'd-none'
        },
        showTabScriptures: function () {
            return this.client.id > 0 && this.client.scriptures.length > 0 ? '' : 'd-none'
        },
        showTabInheritances: function () {
            return this.client.id > 0 && this.client.inheritances.length > 0 ? '' : 'd-none'
        },
        showTabTradings: function () {
            return this.client.id > 0 && this.client.tradings.length > 0 ? '' : 'd-none'
        },
        showTabFormalities: function () {
            return this.client.id > 0 && this.client.formalities.length > 0 ? '' : 'd-none'
        },
        showTabDocuments: function () {
            return this.client.id > 0 ? '' : 'd-none'
        }
    },
    created() {
        this.$store.commit('client/RESET_STATE')
        if (this.$route.params.id) {
            this.edit = true
            this.getClient()
        } else {
            this.edit = false
        }
    },
    beforeDestroy() {
        this.$store.commit('SET_PAGE_TITLE', null)
    },
    methods: {
        getClient() {
            this.showLoading = true

            this.$store.dispatch('client/getClient', {
                id: this.$route.params.id,
                relations: ['responsible', 'insurancePolicies', 'investments', 'financings', 'clientPartners','clientFamilyPartners','clientWorkers', 'requirements',
                    'clientFilterData', 'clientEpigraphs','clientDocuments','clientModels', 'scriptures', 'inheritances', 'tradings', 'formalities']
            })
            .then(response => {
                this.$store.commit('SET_PAGE_TITLE', response.name + ' ' + response.first_surname)

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        sendData() {
            this.$store.dispatch('client/createClient', this.client)
            .then(response => {
                this.$router.replace(CLIENTS_LIST)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Client creat correctament!`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                })
            })
            .catch(error => {
                console.log(error)
            })
        },
        updateData() {
            this.$store.dispatch('client/updateClient', this.client)
            .then(response => {
                this.$router.replace(CLIENTS_LIST)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Client actualitzat correctament!`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                })
            })
            .catch(error => {
                console.log(error)
            })
        },
        cancel() {
            this.$router.push(CLIENTS_LIST)
        },
        tabChanged(newTabIndex, prevTabIndex) {
            this.num_tab = newTabIndex
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>