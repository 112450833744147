<template>
    <b-card title="Col·laboradors" border-variant="primary" class="text-left mt-2">
        <b-row class="col-sm-12" v-for="(client_family_partner, index) in this.client_family_partners"
               :key="client_family_partner.id">

            <b-col sm="3">
                <b-form-group label="Nom complet" label-for="name">
                    <b-form-input v-model="client_family_partner.name" name="name" autocomplete="off"
                                  placeholder="Nom"/>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="DNI" label-for="dni">
                    <b-form-input v-model="client_family_partner.dni" autocomplete="off"
                                  name="dni" placeholder="DNI"/>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group label="DNI document" label-for="dni">
                    <b-form-file
                        v-model="client_family_partner.dni_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1" v-if="client_family_partner.dni_document">
                <a :href="baseUrl+client_family_partner.dni_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>
            <b-form-group label="Familiar de 2º grau" label-for="is_family" class="">
                <div class="demo-inline-spacing">
                    <b-form-radio
                        v-for="bool in boolean"
                        :key="bool.name"
                        v-model="client_family_partner.is_family"
                        :name="'is_family_' + index"
                        class="mt-1"
                        :value="bool.value"
                    >
                        {{ bool.name }}
                    </b-form-radio>
                </div>
            </b-form-group>
            <b-col v-if="!client_family_partner.is_family">
                <feather-icon
                    icon="TrashIcon"
                    variant="outline-danger"
                    class="cursor-pointer mt-0 mt-md-2 ml-2"
                    @click="removeFamilyPartner(index, client_family_partner.id)"
                    size="25"
                />
            </b-col>

            <b-form-group v-if="client_family_partner.is_family" label="Mes de 18" class="mr-2 ml-1"
                          label-for="more_18">
                <v-select
                    v-model="client_family_partner.more_18"
                    label="name"
                    :name="'more_18_' + index"
                    :reduce="(option) => option.value"
                    :options="boolean"
                >
                    <template #no-options="{ }">
                        No s'han pogut carregar les opcions
                    </template>
                </v-select>
            </b-form-group>


            <b-form-group v-if="client_family_partner.more_18 && client_family_partner.is_family"
                          label="Hi ha convivència?" label-for="live_together" class="mr-2 ml-1">
                <v-select
                    v-model="client_family_partner.live_together"
                    label="name"
                    :name="'live_together_' + index"
                    :reduce="(option) => option.value"
                    :options="boolean"
                >
                </v-select>
            </b-form-group>

            <b-col v-if="client_family_partner.live_together && client_family_partner.is_family" sm="3">
                <b-form-group label="Llibre de família" label-for="family_book_file">
                    <b-form-file
                        v-model="client_family_partner.book_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1"
                   v-if="client_family_partner.book_document && client_family_partner.live_together && client_family_partner.is_family">
                <a :href="baseUrl+client_family_partner.book_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>

            <b-col v-if="client_family_partner.live_together && client_family_partner.is_family" sm="3">
                <b-form-group label="Certificat de convivència" label-for="coexistence_certificate">
                    <b-form-file
                        v-model="client_family_partner.coexistence_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1"
                   v-if="client_family_partner.coexistence_document && client_family_partner.live_together && client_family_partner.is_family">
                <a :href="baseUrl+client_family_partner.coexistence_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>
            <b-col v-if="client_family_partner.is_family">
                <feather-icon
                    icon="TrashIcon"
                    variant="outline-danger"
                    class="cursor-pointer mt-0 mt-md-2 ml-2"
                    @click="removeFamilyPartner(index, client_family_partner.id)"
                    size="25"
                />
            </b-col>
            <hr class="alert-primary">
        </b-row>


        <b-row class="col-sm-12">
            <b-col class="pb-2">
                <b-button variant="" @click="addNewRow()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir col·laborador</span>
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {BCard, BCol, BButton, BFormGroup, BFormInput, BFormRadio, BFormCheckbox, BFormFile, BRow} from "bootstrap-vue"
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {baseUrl} from "@/constants/app"
import Swal from "sweetalert2"
import helpers from "@/resources/helpers/helpers"
import {boolean} from "@/constants/constants"
import vSelect from "vue-select"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "FormFamilyPartners",
    components: {
        BCard,
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormCheckbox,
        vSelect
    },
    data() {
        return {
            baseUrl,
            boolean
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client_filter_data: 'client.client_filter_data',
            client_family_partners: 'client.client_family_partners',
            client: 'client'
        }),
    },
    methods: {
        addNewRow() {
            this.client.client_family_partners.push({
                name: null,
                is_family: null,
                dni: null,
                dni_document: null,
                dni_document_file: null,
                book_document_file: null,
                book_document: null,
                coexistence_document_file: null,
                coexistence_document: null,
                more_18: null,
                live_together: null,
            })
        },
        removeFamilyPartner(index, partnerId) {
            if (partnerId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('client/deleteClientFamilyPartner', partnerId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Familiar col·laborador eliminado correctament', 'CheckIcon', 'success')
                            this.client.client_family_partners.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar familiar col·laborador', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.client.client_family_partners.splice(index, 1)
            }
        },
    }
}
</script>

<style scoped>
hr {
    display: block;
    width: 100%;
    height: 1px;
}
</style>