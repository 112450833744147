export const digitalCertificatesOptions = [
	{value: 'deh', name: 'DEH'},
	{value: 'fnmt' , name: 'FNMT'},
]

export const scripturesCategories = [
	{value: 'in_date', name: 'En termini'},
	{value: 'exempt', name: 'Exempt'},
	{value: 'out_of_date' , name: 'Fora de termini'},
	{value: 'prescribed', name: 'Prescrita'},
]
export const scripturesTypes = [
	{value: 'final_construction', name: 'Acta final d\'obra'},
	{value: 'lease', name: 'Arrendament'},
	{value: 'mortgage_cancellation', name: 'Cancel·lació hipoteca'},
	{value: 'buy_sell' , name: 'Compra-venta'},
	{value: 'mortgage_construction', name: 'Constitució hipoteca'},
	{value: 'new_construction_declaration', name: 'Declaració obra nova/divisió horitzontal'},
	{value: 'horizontal_division', name: 'Divisió horitzontal'},
	{value: 'community_dissolution', name: 'Dissolució de comunitat'},
	{value: 'donation', name: 'Donació'},
	{value: 'segregation_properties', name: 'Segregació, agrupació immobles'},
	{value: 'others', name: 'Altres'},
]

export const scripturesTypesTrading = [
	{value: 'constitution', name: 'Constituició'},
	{value: 'real_titularity', name: 'Acta de titularitat real'},
	{value: 'declaration_unipersonality', name: 'Declaració d\'unipersonalitat'},
	{value: 'trading_power' , name: 'Poder mercantil'},
	{value: 'house_change', name: 'Canvi de domicili'},
	{value: 'socials_change', name: 'Canvi òrgans socials'},
	{value: 'object_change', name: 'Canvi objecte social'},
	{value: 'community_dissolution', name: 'Canvi denominació social'},
	{value: 'participation_trading', name: 'Compravenda de participacions'},
	{value: 'capital_increase', name: 'Augment de capital'},
	{value: 'capital_redution', name: 'Reducció de capital'},
	{value: 'society_fusion', name: 'Fusió de societats'},
	{value: 'society_separation', name: 'Escissió de societats'},
	{value: 'others', name: 'Altres'},
]

export const scripturesStatus = [
	{value: 'asked_signed' , name: 'En execució', class: '#ff07caf7'},
	{value: 'signed', name: 'Signat', class: '#ffd507'},
	{value: 'taxes_liquidation', name: 'Liquidació impost', class: '#ff9f43'},
	{value: 'register', name: 'En registre',  class: '#c843fff5'},
	{value: 'finished', name: 'Finalizat',  class: '#28c76f'},
]

export const autonomousCommunities = [
	{value:"Andalucía",name: "Andalucía"},
	{value:"Aragón",name: "Aragón"},
	{value:"Canarias",name: "Canarias"},
	{value:"Cantabria",name: "Cantabria"},
	{value:"Castilla y León",name: "Castilla y León"},
	{value:"Castilla-La Mancha",name: "Castilla-La Mancha"},
	{value:"Cataluña",name: "Cataluña"},
	{value:"Ceuta",name: "Ceuta"},
	{value:"Comunidad Valenciana",name: "Comunidad Valenciana"},
	{value:"Comunidad de Madrid",name: "Comunidad de Madrid"},
	{value:"Extremadura",name: "Extremadura"},
	{value:"Galicia",name: "Galicia"},
	{value:"Islas Baleares",name: "Islas Baleares"},
	{value:"La Rioja",name: "La Rioja"},
	{value:"Melilla",name: "Melilla"},
	{value:"Navarra",name: "Navarra"},
	{value:"País Vasco",name: "País Vasco"},
	{value:"Principado de Asturias",name: "Principado de Asturias"},
	{value:"Región de Murcia",name: "Región de Murcia"},
]

export const inheritanceCategories = [
	{value: 'tested', name: 'Testada'},
	{value: 'untested' , name: 'Intestada'},
]

export const inheritanceTypes = [
	{value: 'testamentary_office' , name: 'Testamentaria en despatx'},
	{value: 'testamentary_external', name: 'Testamentaria en extern'},
]

