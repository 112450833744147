<template>
    <div>
        <b-button variant="primary" @click="goToCreate()">
            Crear tràmit mercantil
        </b-button>
    </div>
</template>


<script>
import {BRow, BCol, BButton, BCard, BBadge, BOverlay} from 'bootstrap-vue'
import vSelect from "vue-select"
import {TRADING_CREATE} from "@/router/web/constants"

export default {
  name: "CreateTradingButton",
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BOverlay,

    vSelect,
  },
  methods:{
    goToCreate() {
      this.$router.push(TRADING_CREATE)
    },
  }
}
</script>