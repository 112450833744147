<template>
    <div>
        <b-row>
            <b-col sm="12" class="mb-1 mt-1">
                <h4>Epígrafs</h4>
            </b-col>
            <b-row class="col-sm-12" v-for="(client_epigraph, index) in this.client_epigraphs"
                   :key="client_epigraph.id">
                <b-col lg="3" md="4" sm="4">
                    <b-form-group label="Activitat" label-for="epigrafes">
                        <v-select
                            v-model="client_epigraph.activity_id"
                            label="name"
                            :reduce="(option) => option.id"
                            :options="epigraphsTypes"
                            :disabled="readOnly"

                            @input="test(index)"
                        >
                            <template #no-options="{ }">
                                No s'han pogut carregar els clients
                            </template>
                        </v-select>
                        <small v-if="validation_client.activity_id" class="text-danger">
                            {{ validation_client.activity_id }}
                        </small>
                    </b-form-group>
                </b-col>
                <b-col lg="8" md="12" sm="12">
                    <b-form-group label="Epígrafs IAE" label-for="epigrafes">
                        <v-select
                            v-model="client_epigraph.epigraphs"
                            multiple
                            :reduce="(option) => option.id"
                            :options="epigraphs.filter(item => (item.section === client_epigraph.activity_id))"
                            label="description"
                            :disabled="readOnly"
                        >
                            <template #no-options="{ }">
                                No s'han pogut carregar els epigrafes
                            </template>
                            <template v-slot:selected-option="option">
                                <div :class="readOnly ? 'selected-option-disabled' : ''" v-html="option.description"></div>
                            </template>
                        </v-select>

                    </b-form-group>
                </b-col>
                <b-col sm="1" class="mt-2">
                    <b-button
                        variant="outline-danger"
                        :disabled="readOnly"
                        @click="removeItem(index, client_epigraph.id)"
                    >
                        <feather-icon
                            icon="TrashIcon"
                        />
                    </b-button>
                </b-col>
                <b-row class="col-sm-12">
                    <b-col lg="4" md="12" sm="12">
                        <b-form-group label="Alta IAE" label-for="date-document">
                            <b-form-file
                                v-model="client_epigraph.iae_document_file"
                                :disabled="readOnly"
                                placeholder="Tria un document o deixa'l anar aquí..."
                                drop-placeholder="Tria un document o deixa'l anar aquí..."
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_epigraph.iae_document" lg="1" md="12" sm="12" class="pl-0">
                        <a :href="baseUrl+client_epigraph.iae_document" target="_blank">
                            <b-button
                                variant="outline-success"
                                class="mt-2"
                            >
                                <feather-icon
                                    icon="EyeIcon"
                                    class="mr-15"

                                />
                            </b-button>
                        </a>
                    </b-col>
                    <b-col v-if="client_epigraph.iae_document" lg="1" md="12" sm="12" class="mt-2">
                        <b-button
                            variant="outline-danger"
                            :disabled="readOnly"
                            @click="client_epigraph.iae_document_file = client_epigraph.iae_document = null "
                        >
                            <feather-icon
                                icon="TrashIcon"
                                class="mr-15"
                            />
                        </b-button>
                    </b-col>
                    <b-col lg="4" md="12" sm="12">
                        <b-form-group label="Alta Censal" label-for="date-document" class="pl-1">
                            <b-form-file
                                v-model="client_epigraph.censal_document_file"
                                :disabled="readOnly"
                                placeholder="Tria un document o deixa'l anar aquí..."
                                drop-placeholder="Tria un document o deixa'l anar aquí..."
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_epigraph.censal_document" lg="1" md="12" sm="12"  class="pl-0">
                        <a :href="baseUrl+client_epigraph.censal_document" target="_blank">
                            <b-button
                                variant="outline-success"
                                class="mt-0 mt-md-2"
                            >
                                <feather-icon
                                    icon="EyeIcon"
                                    class="mr-15"

                                />
                            </b-button>
                        </a>
                    </b-col>
                    <b-col v-if="client_epigraph.censal_document" lg="1" md="12" sm="12" class="mt-2">
                        <b-button
                            variant="outline-danger"
                            :disabled="readOnly"
                            @click="client_epigraph.censal_document_file = client_epigraph.censal_document = null "
                        >
                            <feather-icon
                                icon="TrashIcon"
                                class="mr-15"
                            />
                        </b-button>
                    </b-col>
                    <b-col lg="2" md="12" sm="12">
                        <b-form-group
                            label-for="date-effect"
                            label="Data baixa activitat"
                        >
                            <flat-pickr
                                v-model="client_epigraph.date_leave_activity"
                                class="form-control"
                                name="date"
                                :disabled="readOnly"
                                placeholder="Data baixa activitat"
                                :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr/>
            </b-row>
            <b-row class="col-sm-12">
                <b-col class="pb-2">
                    <b-button variant="" :disabled="readOnly" @click="addNewRow()">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-25"

                        />
                        <span>Afegir Activitat</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-row>
    </div>
</template>

<script>
import {BCol, BFormGroup, BRow, BButton, BFormFile, BFormInput} from "bootstrap-vue"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"
import {epigraphsTypes} from "@/constants/accounting"
import {baseUrl} from "@/constants/app"
import Swal from "sweetalert2"
import ToastificationContent from "@core/components/toastification/ToastificationContent"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    props: ['readOnly'],
    name: "FormEpigraphs",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BButton,
        BFormFile,
        BFormInput,
        vSelect,
        flatPickr
    },
    data() {
        return {
            epigraphsTypes,
            epigraphs: [],
            currentEpigraphType: null,
            epigraphsFromOldState: [],
            baseUrl,
            CatalanLocale
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client: 'client',
            client_filter_data: 'client.client_filter_data',
            client_epigraphs: 'client.client_epigraphs'
        }),
    },
    watch: {
        'client_epigraph.activity_id': function (newValue, oldValue) {
            if (newValue && !oldValue) {
                this.currentEpigraphType = newValue
                this.epigraphsFromOldState = this.client_epigraphs
            }
            if (newValue !== this.currentEpigraphType) {
                this.client_epigraphs = []
            } else {
                this.client_epigraphs = this.epigraphsFromOldState
            }
            this.getEpigraphs(newValue)
        },
    },
    mounted() {
        this.getEpigraphs()
    },
    methods: {
        getEpigraphs() {
            this.showLoading = true

            this.$store.dispatch('requirements/getEpigraphs')
            .then(response => {
                this.epigraphs = response

                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
                this.showToast('Error en obtenir epígrafs', 'AlertOctagonIcon', 'danger')
            })
        },
        addNewRow() {
            this.client.client_epigraphs.push({
                client_id: null,
                epigraphs: [],
                activity_id: null,
                iae_document_file: null,
                iae_document: null,
                censal_document_file: null,
                censal_document: null,
                date_leave_activity: null,
            })
        },
        removeItem(index, epigraphRowId) {
            if (epigraphRowId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('client/deleteEpigraphRecord', epigraphRowId)
                        .then(response => {
                            this.showLoading = false
                            this.showToast('Epigraf esborrat correctament', 'CheckIcon', 'success')
                            this.client.client_epigraphs.splice(index, 1)

                        })
                        .catch(error => {
                            this.showLoading = false
                            this.showToast('Error a eliminar epígrafe', 'CheckIcon', 'Alert')
                            console.log(error)
                        })
                    }
                })
            } else {
                this.client.client_epigraphs.splice(index, 1)
            }
        },
        test(index) {
            this.client_epigraphs[index].epigraphs = []
            this.getEpigraphs()
        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
    }
}
</script>

<style scoped>

hr {
    display:block;
    width: 100%;
    height: 1px;
}
.selected-option-disabled{
    background-color: cadetblue !important;
    border-radius: 3px;
    padding: 0 0.6em;
}
</style>