<template>
    <div>
        <b-row>
            <b-col cols="8">
                <div class="mb-2">
                    <h4>Filtre</h4>
                </div>
            </b-col>
            <b-col lg="4" md="4" class="text-right">
                <b-button variant="primary" @click="cleanFilters">
                    Netejar filtres
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!this.$route.params.id" cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Client" label-for="Client">
                    <b-form-input v-model="searchFields.client" placeholder="Client" autocomplete="off"
                                  @update="searchInheritances()"/>
                </b-form-group>
            </b-col>
            <b-col v-if="!this.$route.params.id" cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="NIF/CIF" label-for="Client">
                    <b-form-input v-model="searchFields.nif_cif" placeholder="NIF/CIF" autocomplete="off"
                                  @update="searchInheritances()"/>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Tipus" label-for="Tipus">
                    <select v-model="searchFields.type" class="custom-select" @change="searchInheritances()">
                        <option value=""></option>
                        <option v-for="type in inheritanceTypes"
                                :key="type.value"
                                :value="type.value"> {{ type.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Categoria" label-for="Categoria">
                    <select v-model="searchFields.category" class="custom-select" @change="searchInheritances()">
                        <option value=""></option>
                        <option v-for="category in inheritanceCategories"
                                :key="category.value"
                                :value="category.value"> {{ category.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Notari" label-for="Notari">
                    <b-form-input v-model="searchFields.notary" placeholder="Notari" autocomplete="off"
                                  @update="searchInheritances()"/>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Data signatura" label-for="Data signatura">
                    <flat-pickr
                        v-model="searchFields.signature_date"
                        class="form-control"
                        placeholder="Data signatura"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchInheritances()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Protocol" label-for="Protocol">
                    <b-form-input v-model="searchFields.protocol" placeholder="Protocol" autocomplete="off"
                                  @update="searchInheritances()"/>
                </b-form-group>
            </b-col>
            <b-col cols="2" lg="3" md="4" sm="2" xl="3">
                <b-form-group label="Data devengo" label-for="Data devengo">
                    <flat-pickr
                        v-model="searchFields.accrual_date"
                        class="form-control"
                        placeholder="Data devengo"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchInheritances()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="2" lg="3" md="4" sm="2" xl="3">
                <b-form-group label="Data limit" label-for="Data limit">
                    <flat-pickr
                        v-model="searchFields.limit_date"
                        class="form-control"
                        placeholder="Data limit"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchInheritances()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="2" lg="3" md="4" sm="2" xl="3">
                <b-form-group label="Data prescripció" label-for="Data prescripció">
                    <flat-pickr
                        v-model="searchFields.prescription_date"
                        class="form-control"
                        placeholder="Data prescripció"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchInheritances()"
                    />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup,
    BIcon,
    BInputGroupText,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import TableInheritances from "@/views/trading/inheritances/list/components/tableInheritances"
import CreateInheritanceButton from "@/views/trading/inheritances/list/components/createInheritanceButton"
import {createHelpers} from "vuex-map-fields"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"
import {inheritanceCategories, inheritanceTypes} from "@/constants/trading"
const {mapFields} = createHelpers({
    getterType: 'inheritances/getField',
    mutationType: 'inheritances/updateField',
})

export default {
    name: "FilterInheritances",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        BIcon,
        BInputGroupText,

        vSelect,
        flatPickr,

        TableInheritances,
        CreateInheritanceButton,

        inheritanceCategories,
        inheritanceTypes,

    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            showLoading: false,
            searchFields: [],
            searchConfig: [],

            CatalanLocale,
            inheritanceCategories,
            inheritanceTypes
        }
    },
    watch: {
        totalRecords (currentValue) {
            this.$emit('update', currentValue);
        }
    },
    beforeMount() {
        this.$store.commit('inheritances/RESET_STATE')
        this.$root.$on('callGetInheritances', () => {
            this.getInheritances()
        })
        this.getInheritances()
    },
    methods: {
        searchInheritances() {
            this.getInheritances()
        },
        cleanFilters() {
            this.searchFields = []
            this.getInheritances()
        },
        getInheritances() {
            this.showLoading = true
            this.searchConfig = [
                {
                    'type': 'concat_strings',
                    'concat_fields': ['clients.name', 'clients.first_surname', 'clients.second_surname'],
                    'searchTerm': this.searchFields.client ?? ''
                },
                {'type': 'string', 'column': 'clients.nif_cif', 'searchTerm': this.searchFields.nif_cif ?? ''},
                {'type': 'string', 'column': 'inheritances.type', 'searchTerm': this.searchFields.type ?? ''},
                {'type': 'string', 'column': 'category', 'searchTerm': this.searchFields.category ?? ''},
                {'type': 'string', 'column': 'notary', 'searchTerm': this.searchFields.notary ?? ''},
                {'type': 'string', 'column': 'protocol', 'searchTerm': this.searchFields.protocol ?? ''},
                {'type': 'string', 'column': 'accrual_date', 'searchTerm': this.searchFields.accrual_date ?? ''},
                {'type': 'string', 'column': 'limit_date', 'searchTerm': this.searchFields.limit_date ?? ''},
                {'type': 'string', 'column': 'prescription_date', 'searchTerm': this.searchFields.prescription_date ?? ''},
                {'type': 'string', 'column': 'signature_date', 'searchTerm': this.searchFields.signature_date ?? ''},
            ]

            this.$store.dispatch('inheritances/searchInheritances', {
                client_id: this.$route.params.id ?? '',
                per_page: this.pageLength,
                page: this.page,
                filter: this.searchConfig.length > 0 ? this.searchConfig : '',
                relations: ['client']
            })
            .then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records
                this.$emit('update',this.totalRecords)
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>