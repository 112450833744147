<template>
    <div>
        <b-table
            ref="refTradingListTable"
            class="position-relative"
            responsive
            primary-key="id"
            show-empty
            empty-text="No s'han trobat tràmits"
            :items="tradings"
            :fields="columns"
        >
            <template #cell(client)="data">
                <router-link v-if="currentRouteName !== 'clients_edit'"
                             :to="{ name: 'clients_edit', params: { id: data.item.client_id, activeTab: 'tradings'} }">
                    {{ data.item.client.name }}  {{ data.item.client.first_surname }}  {{ data.item.client.second_surname }}
                </router-link>
                <div v-else> {{ data.item.client.name }}  {{ data.item.client.first_surname }}  {{ data.item.client.second_surname }}</div>
            </template>

            <template #cell(nif_cif)="data">
                {{ data.item.client.nif_cif }}
            </template>

            <template #cell(limit_date)="data">
                {{data.item.limit_date !== null ? helpers.formatDate(data.item.limit_date) : ''}}
            </template>

            <template #cell(signature_date)="data">
                {{data.item.signature_date !== null ? helpers.formatDate(data.item.signature_date) : ''}}
            </template>

            <template #cell(prescription_date)="data">
                {{data.item.prescription_date !== null ? helpers.formatDate(data.item.prescription_date) : ''}}
            </template>

            <template #cell(accrual_date)="data">
                {{data.item.accrual_date !== null ? helpers.formatDate(data.item.accrual_date) : ''}}
            </template>

            <template #cell(scripture_type)="data">
                <span class="badge badge-primary text-uppercase">
                    {{ data.item.scripture_type !== null ? scripturesTypesTrading.find(element => element.value == data.item.scripture_type).name : ''}}
                </span>
            </template>

            <template #cell(status)="data">
                <span class="badge text-uppercase" :style="{ backgroundColor: getBackGroundColor(data.item.status)}">
                    {{ data.item.status !== null ? scripturesStatus.find(element => element.value == data.item.status).name : ''}}
                </span>
            </template>

            <template #cell(actions)="data">
                <div>
                    <router-link v-show="data.item.id" :to="{ name: 'trading_edit', params: { id: data.item.id} }">
                        <feather-icon
                            v-if="can('edit_trading')"
                            :id="`invoice-row-${data.item.id}-send-icon`"
                            icon="EditIcon"
                            class="cursor-pointer"
                            size="16"
                        />
                    </router-link>

                    <feather-icon
                        v-if="can('delete_trading')"
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer mx-1 text-red"
                        @click="deleteTrading(data.item.id)"
                    />
                </div>
            </template>
        </b-table>

    </div>
</template>


<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup
} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import Swal from "sweetalert2"
import helpers from "@/resources/helpers/helpers"
import {scripturesStatus,scripturesTypesTrading} from "@/constants/trading"

export default {
    name: "TableTrading",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        vSelect,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,

            columns: [
                {key: 'client', label: 'Client', sortable: false},
                {key: 'nif_cif', label: 'NIF/CIF', sortable: false},
                {key: 'scripture_type', label: 'Tipus d\'escriptura', sortable: false},
                {key: 'signature_date', label: 'Data signatura', sortable: false},
                {key: 'protocol', label: 'Protocol', sortable: false},
                {key: 'accrual_date', label: 'Data devengo', sortable: false},
                {key: 'limit_date', label: 'Data limit', sortable: false},
                {key: 'status', label: 'Estat', sortable: false},
                {key: 'actions', label: 'Accions', sortable: false},
            ],

            helpers,
            scripturesStatus,
            scripturesTypesTrading
        }
    },
    computed: {
        ...mapState('trading', ['tradings']),
        currentRouteName() {
            return this.$route.name
        },

    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        getBackGroundColor(status){
            return scripturesStatus.find(element => element.value == status) ? scripturesStatus.find(element => element.value == status).class : 'default'
        },
        deleteTrading(id) {
            Swal.fire({
                title: "Esteu segur d'esborrar el tràmit mercantil?",
                text: "Un cop esborrat no ho podràs recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, esborra'l!",
                cancelButtonText: "Cancel·lar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('trading/deleteTrading', {id: id})
                    .then(response => {
                        this.showLoading = false
                        this.helpers.showToast('Tràmit eliminat correctament', 'CheckIcon', 'success')
                        this.$root.$emit('callGetTradings')
                    })
                    .catch(error => {
                        this.showLoading = false
                        console.log(error)
                    })
                }
            })
        },
    },
}
</script>