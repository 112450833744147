
export const trimesters = [
	{value: '1', name: 'Primer'},
	{value: '2', name: 'Segon'},
	{value: '3', name: 'Tercer'},
	{value: '4', name: 'Quart'},
	{value: 'annual', name: 'Anual'},
]
export const requirementsStatus = [
	{id:'1', value: 'preparation' , name: 'En preparació', class: 'light-primary'},
	{id:'2', value: 'allegation', name: 'Redacció de l\'al·legació', class: 'light-warning'},
	{id:'3', value: 'review', name: 'En revisió', class: 'light-warning'},
	{id:'4', value: 'waiting_telematic_shipment', name: 'Esperant enviament telemàtic', class: 'light-info'},
	{id:'5', value: 'waiting_resolution', name: 'Esperant resolució', class: 'light-info'},
	{id:'6', value: 'solved_accepted', name: 'Al·legació acceptada', class: 'light-success'},
	{id:'7', value: 'solved_rejected', name: 'Al·legació rebutjada', class: 'light-danger'},
	{id:'8', value: 'modifying_allegation', name: 'Modificant al·legació', class: 'light-warning'},
	{id:'9', value: 'waiting_allegation_finalization', name: 'Esperant finalització d\'al.legació', class: 'light-info'},
]
export const epigraphsTypes = [
	{id: '1' , name: 'Resta Empresarials'},
	{id: '2', name: 'Professionals'},
	{id: '3', name: 'Artistes i esportistes'},
]
