<template>
    <div>
        <b-table
            ref="refFinancingListTable"
            class="position-relative"
            responsive
            primary-key="id"
            show-empty
            empty-text="No s'han trobat finançaments"
            :items="financings"
            :fields="columns"
        >
            <template #cell(status)="data">
                <span
                    :class="['text-uppercase badge badge-pill badge-light-'+getStatusColor(data.item.status) ]"> {{ data.item.status }} </span>
            </template>

            <template #cell(client)="data">
                <router-link v-if="currentRouteName !== 'clients_edit'"
                             :to="{ name: 'clients_edit', params: { id: data.item.client_id, activeTab: 'financings'} }">
                    {{ data.item.client }}
                </router-link>
                <div v-else> {{ data.item.client }}</div>
            </template>

            <template #cell(amount)="data">
          <span>
              <money style="border-style: hidden" readonly="true" v-model="data.item.amount"
                     v-bind="money">{{ data.item.amount }}</money>
          </span>
            </template>

            <template #cell(actions)="data">
                <div>
                    <router-link :to="{ name: 'financing_edit', params: { id: data.item.id} }">
                        <feather-icon
                            v-if="can('edit_financing')"
                            :id="`invoice-row-${data.item.id}-send-icon`"
                            icon="EditIcon"
                            class="cursor-pointer"
                            size="16"
                        />
                    </router-link>

                    <feather-icon
                        v-if="can('delete_financing')"
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer mx-1 text-red"
                        @click="deleteFinancing(data.item.id)"
                    />
                    <b-tooltip
                        title="Eliminar Finançament"
                        :target="`invoice-row-${data.item.id}-preview-icon`"
                    />
                </div>
            </template>
        </b-table>
    </div>
</template>


<script>
import {BRow, BCol, BTable, BCard, BBadge, BButton, BTooltip, BPagination, BFormInput, BOverlay} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import Swal from "sweetalert2"
import ToastificationContent from "@core/components/toastification/ToastificationContent"

export default {
    name: "TableFinancings",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,

        vSelect,
    },
    data() {
        return {

            page: 1,
            pageLength: 10,
            totalRecords: 10,

            columns: [
                {key: 'financing_entity', label: 'Entitat'},
                {key: 'client', label: 'Client', sortable: false},
                {key: 'amount', label: 'Import', sortable: false},
                {key: 'status', label: 'Estat', sortable: false},
                {key: 'actions', label: 'Accions', sortable: false},
            ],
        }
    },
    computed: {
        ...mapState('financing', ['financings']),
        currentRouteName() {
            return this.$route.name
        }

    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        getStatusColor: function (status) {
            switch (status) {
                case 'En Curs':
                    return 'warning'
                case 'Acceptat':
                    return 'success'
                case 'Cancel·lat':
                    return 'secondary'
                case 'Denegada':
                    return 'danger'
            }
        },
        deleteFinancing(id) {
            Swal.fire({
                title: "Esteu segur d'esborrar el finançament?",
                text: "Un cop esborrat no ho podràs recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, esborra'l!",
                cancelButtonText: "Cancel·lar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('financing/deleteFinancing', {id: id})
                    .then(response => {
                        this.showLoading = false
                        this.showToast('Finançament eliminat correctament', 'CheckIcon', 'success')
                        this.$root.$refs.ListFinancings.getFinancings()
                    })
                    .catch(error => {
                        this.showLoading = false
                        console.log(error)
                    })
                }
            })
        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
    }
}
</script>