<template>
    <b-card v-if="client.id">
        <b-row class="mt-1 mb-1">
            <b-col xl="12" class="text-center">
                <b-form-group label="Client" label-for="client">
                    <div class="demo-center-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.already_client"
                            name="is_client"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="12" class="text-center">
                <b-form-group label="Tipus activitat economica" label-for="account-username">
                    <div class="demo-center-spacing">
                        <b-form-radio
                            v-for="typeEconomicActivity in typeEconomicsActivity"
                            :key="typeEconomicActivity.name"
                            v-model="client.type_economic_activity"
                            name="typeEconomicActivity"
                            class="mt-1"
                            :value="typeEconomicActivity.value"
                        >
                            {{ typeEconomicActivity.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-1 mb-1">
            <b-col xl="12" class="text-center">
                <b-form-group label="Tipus de client" label-for="account-username">
                    <div class="demo-center-spacing">
                        <b-form-radio
                            v-for="typeClient in typeClients"
                            :key="typeClient.name"
                            v-model="client.type"
                            name="typeClient"
                            class="mt-1"
                            :value="typeClient.value"
                        >
                            {{ typeClient.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="client.type=='physical_person'" class="mt-1 mb-1">
            <b-col xl="12" class="text-center">
                <b-form-group label="Esta donat de alta?" label-for="is_registered">
                    <div class="demo-center-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.incorporated_company"
                            name="is_registered"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="client.type=='legal_person'" class="mt-1 mb-1">
            <b-col xl="12" class="text-center">
                <b-form-group label="Disposa d'una societat ja constituïda?" label-for="incorporated_company">
                    <div class="demo-center-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.incorporated_company"
                            name="incorporated_company"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="client.type=='legal_person' && !client_filter_data.incorporated_company">
            <b-col cols="6">
                <b-form-group label="Nom de la societat" label-for="first_company_name">
                    <b-form-input v-model="client_filter_data.first_company_name" autocomplete="off"
                                  name="first_company_name" placeholder="Opció 1"/>
                </b-form-group>
                <b-form-group label="" label-for="second_company_name">
                    <b-form-input v-model="client_filter_data.second_company_name" autocomplete="off"
                                  name="second_company_name" placeholder="Opció 2"/>
                </b-form-group>
                <b-form-group label="" label-for="third_company_name">
                    <b-form-input v-model="client_filter_data.third_company_name" autocomplete="off"
                                  name="third_company_name" placeholder="Opció 3"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="client.type=='legal_person' && client_filter_data.incorporated_company">
            <b-col cols="6">
                <b-form-group label="Com ens has conegut?" label-for="how_you_meet_us">
                    <b-form-input v-model="client_filter_data.how_you_meet_us" autocomplete="off"
                                  name="how_you_meet_us" placeholder=""/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="client.type=='legal_person' && client_filter_data.incorporated_company">
            <b-col cols="4">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="constitution_deed">
                            <b-form-checkbox
                                id="constitution_deed"
                                v-model="client_filter_data.constitution_deed"
                                name="constitution_deed"
                                :value="1"
                            >
                                Escriptura constitució
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.constitution_deed" cols="9">
                        <b-form-group label="Document" label-for="file_constitution_deed">
                            <b-form-file
                                v-model="client_filter_data.file_constitution_deed"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.constitution_deed && client_filter_data.file_constitution_deed
                        && ( typeof client_filter_data.file_constitution_deed!='object')" cols="3" class="mt-2 p-0">
                        <a :href="baseUrl+client_filter_data.file_constitution_deed" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer"
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_constitution_deed')"
                        />
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="powers_in_force">
                            <b-form-checkbox
                                id="powers_in_force"
                                v-model="client_filter_data.powers_in_force"
                                name="powers_in_force"
                                :value="1"
                            >
                                Poders en vigor
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.powers_in_force" cols="9">
                        <b-form-group label="Document" label-for="file_powers_in_force">
                            <b-form-file
                                v-model="client_filter_data.file_powers_in_force"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.powers_in_force && client_filter_data.file_powers_in_force
                            && ( typeof client_filter_data.file_powers_in_force!='object')" cols="3" class="mt-2 p-0">
                        <a :href="baseUrl+client_filter_data.file_powers_in_force" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_powers_in_force')"
                        />
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="act_real_ownership">
                            <b-form-checkbox
                                id="act_real_ownership"
                                v-model="client_filter_data.act_real_ownership"
                                name="act_real_ownership"
                                :value="1"
                            >
                                Acta de titularitat real
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.act_real_ownership" cols="9">
                        <b-form-group label="Document" label-for="file_act_real_ownership">
                            <b-form-file
                                v-model="client_filter_data.file_act_real_ownership"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.act_real_ownership && client_filter_data.file_act_real_ownership
                                    && ( typeof client_filter_data.file_act_real_ownership!='object')" cols="3"
                           class="mt-2 p-0">
                        <a :href="baseUrl+client_filter_data.file_act_real_ownership" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_act_real_ownership')"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <form-partners v-if="client.type == 'legal_person' && client_filter_data.incorporated_company"/>
        <b-row cols="3">
            <b-col  v-if="client.client_partners.length > 0">
                <b-form-group label="Quants socis seran treballadors?"
                              label-for="corporate_autonomous_leave">
                    <v-select
                        v-model="client_filter_data.worker_partners"
                        :options="quantityPartnersOptions"
                        label="name"
                        :reduce="(option) => option.value"
                    >
                    </v-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="client.type=='legal_person' && !client_filter_data.incorporated_company">
            <b-col cols="6">
                <b-form-group label="Tipus de activitat" label-for="activity_type">
                    <b-form-input v-model="client_filter_data.activity_type" autocomplete="off"
                                  name="activity_type" placeholder=""/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="client.type=='legal_person' && client_filter_data.incorporated_company" class="mt-1 mb-1">
            <b-col xl="12">
                <b-form-group label="Hauràn autònoms societaris?" label-for="corporate_autonomous">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.corporate_autonomous"
                            name="corporate_autonomous"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="client.type=='legal_person' && client_filter_data.incorporated_company" class="mt-1 mb-1">
            <b-col xl="12">
                <b-form-group label="S'ha de donar de baixa com autònoms societaris?"
                              label-for="corporate_autonomous_leave">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.corporate_autonomous_leave"
                            name="corporate_autonomous_leave"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="client.type=='legal_person' && client_filter_data.incorporated_company">
            <b-col cols="6">
                <b-form-group label="Observacions" label-for="observations">
                    <b-form-input v-model="client_filter_data.observations" autocomplete="off"
                                  name="observations" placeholder=""/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="!client_filter_data.incorporated_company">
            <b-col cols="12">
                <b-form-group label="Intenció / Possibilitat de capitalitzar l'atur" label-for="account-username">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.capitalize_unemployment"
                            name="capitalize_unemployment"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="10" v-if="client_filter_data.capitalize_unemployment">
                <div class="text-center">
                    <b-badge
                        class="d-block p-1 mb-1"
                        variant="warning"
                    >
                        ATENCIÓ CAPITALITZACIÓ. NO PRESENTAR RES.
                    </b-badge>
                </div>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Tramita vostè la capitalització?" label-for="account-username">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.capitalize_yourself"
                            name="capitalize_yourself"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="!client_filter_data.incorporated_company && client_filter_data.capitalize_yourself">
            <b-col cols="3">
                <b-form-group label="" label-for="proforma_invoice">
                    <b-form-checkbox
                        id="proforma_invoice"
                        v-model="client_filter_data.proforma_invoice"
                        name="proforma_invoice"
                        :value="1"
                    >
                        Factura Proforma
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col v-if="client_filter_data.proforma_invoice" cols="6">
                <b-form-group label="" label-for="file_proforma_invoice">
                    <b-form-file
                        v-model="client_filter_data.file_proforma_invoice"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="client_filter_data.proforma_invoice && client_filter_data.file_proforma_invoice
                && ( typeof client_filter_data.file_proforma_invoice!='object')" cols="2" class="">
                <a :href="baseUrl+client_filter_data.file_proforma_invoice" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 "
                        size="25"
                    />
                </a>
                <feather-icon
                    icon="TrashIcon"
                    class="cursor-pointer text-danger ml-1"
                    size="25"
                    @click="deleteDataFilterDocument('file_proforma_invoice')"
                />
            </b-col>
        </b-row>
        <b-row v-if="!client_filter_data.incorporated_company && client_filter_data.capitalize_yourself">
            <b-col cols="3">
                <b-form-group label="" label-for="precontract_rent">
                    <b-form-checkbox
                        id="precontract_rent"
                        v-model="client_filter_data.precontract_rent"
                        name="precontract_rent"
                        :value="1"
                    >
                        Precontracte lloguer
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col v-if="client_filter_data.precontract_rent" cols="6">
                <b-form-group label="" label-for="date-document">
                    <b-form-file
                        v-model="client_filter_data.file_precontract_rent"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="client_filter_data.precontract_rent && client_filter_data.file_precontract_rent
                && ( typeof client_filter_data.file_precontract_rent!='object')" cols="2" class="">
                <a :href="baseUrl+client_filter_data.file_precontract_rent" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 "
                        size="25"
                    />
                </a>
                <feather-icon
                    icon="TrashIcon"
                    class="cursor-pointer text-danger ml-1"
                    size="25"
                    @click="deleteDataFilterDocument('file_precontract_rent')"
                />
            </b-col>
        </b-row>
        <b-row v-if="!client_filter_data.incorporated_company">
            <b-col cols="12">
                <b-form-group label="Te dret a l'atur?" label-for="unemployed_right">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.unemployed_right"
                            name="unemployed_right"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Ho ha sol·licitat?" label-for="unemployed_applied">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.unemployed_applied"
                            name="unemployed_applied"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Li han concedit?" label-for="unemployed_granted">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.unemployed_granted"
                            name="unemployed_granted"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Té certificat electrònic en vigor?" label-for="has_cerfiticate">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.has_cerfiticate"
                            name="has_cerfiticate"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
            <b-col v-if="client.type == 'physical_person'" cols="12">
                <b-form-group label="Té col·laborador?" label-for="has_collab">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.has_collabs"
                            name="has_collab"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Té treballadors?" label-for="has_workers">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.has_workers"
                            name="has_collab"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <form-family-partners v-if="client.type == 'physical_person' && client.client_filter_data.has_collabs"/>
        <form-workers v-if="client.client_filter_data.has_workers"/>
        <b-row>
            <b-col sm="12" class="pb-1 pt-1">
                <h4>Si es requereix un local per exercir una activitat</h4>
            </b-col>
            <b-col cols="6">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="feasible_town_hall">
                            <b-form-checkbox
                                id="feasible_town_hall"
                                v-model="client_filter_data.feasible_town_hall"
                                name="feasible_town_hall"
                                :value="1"
                                @change="checkFeasibleTownHall"
                            >
                                Necesita permis municipal d'obertura
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.feasible_town_hall" cols="9">
                        <b-form-group label="" label-for="feasible_town_hall">
                            <b-form-file
                                v-model="client_filter_data.file_feasible_town_hall"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        v-if="client_filter_data.feasible_town_hall && client_filter_data.file_feasible_town_hall &&
                        ( typeof client_filter_data.file_feasible_town_hall!='object')"
                        cols="3" class="mt-0">
                        <a :href="baseUrl+client_filter_data.file_feasible_town_hall" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_feasible_town_hall')"
                        />
                    </b-col>
                </b-row>

            </b-col>
            <b-col cols="6">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="harmless_activity">
                            <b-form-checkbox
                                id="harmless_activity"
                                v-model="client_filter_data.harmless_activity"
                                name="harmless_activity"
                                :value="1"
                                @change="checkHarmlessActivity"
                            >
                                Activitat inocua
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.harmless_activity" cols="9">
                        <b-form-group label="" label-for="file_harmless_activity">
                            <b-form-file
                                v-model="client_filter_data.file_harmless_activity"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        v-if="client_filter_data.harmless_activity && client_filter_data.file_harmless_activity &&
                        ( typeof client_filter_data.file_harmless_activity!='object')"
                        cols="3" class="mt-0">
                        <a :href="baseUrl+client_filter_data.file_harmless_activity" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_harmless_activity')"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <b-form-group label="És local de lloguer o de propietat?"
                              label-for="corporate_autonomous_leave">
                </b-form-group>
            </b-col>
            <b-col>
                <b-row>
                    <b-col cols="2" class="mb-2">
                        <div>
                            <div class="custom-control custom-radio mt-1" v-for="(val, key) in list">
                                <input class="custom-control-input" name="property_type" type="radio"
                                       :checked="client_filter_data.selected_place == val" :id="val"
                                       @click="uncheck(val)" :key="'input' + key">
                                <label class="custom-control-label" :for="val" :key="'label' + key">{{ val }}</label>
                            </div>
                        </div>
                    </b-col>
                    <b-col v-if="client_filter_data.rent_place == 1" cols="6">
                        <b-form-group label="" label-for="file_rental_contract">
                            <b-form-file
                                v-model="client_filter_data.file_rental_contract"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        v-if="client_filter_data.rent_place == 1 && client_filter_data.file_rental_contract
                         && ( typeof client_filter_data.file_rental_contract!='object')"
                        cols="2" class="p-0">
                        <a :href="baseUrl+client_filter_data.file_rental_contract" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_rental_contract')"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="client_filter_data.feasible_town_hall || client_filter_data.harmless_activity" cols="6">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="adaptation_works">
                            <div>
                                Son necessàries obres d'adecuació?
                            </div>
                            <div class="demo-inline-spacing">
                                <b-form-radio
                                    v-for="bool in boolean"
                                    :key="bool.name"
                                    v-model="client_filter_data.adaptation_works"
                                    name="adaptation_works"
                                    class="mt-1"
                                    :value="bool.value"
                                >
                                    {{ bool.name }}
                                </b-form-radio>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row v-if="client_filter_data.rent_place || client_filter_data.property_place">
            <b-col cols="12">
                <b-form-group label="Direcció" label-for="address">
                    <b-form-input v-model="client_filter_data.address" autocomplete="off"
                                  name="address" placeholder=""/>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Codi postal" label-for="postal_code">
                    <b-form-input v-model="client_filter_data.postal_code" autocomplete="off"
                                  name="postal_code" placeholder=""/>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group label="Població" label-for="city">
                    <b-form-input v-model="client_filter_data.city" autocomplete="off"
                                  name="city" placeholder=""/>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="m²" label-for="construction_meters">
                    <b-form-input v-model="client_filter_data.construction_meters" autocomplete="off"
                                  name="construction_meters" placeholder=""/>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group label="Referència cadastral" label-for="register_reference">
                    <b-form-input v-model="client_filter_data.register_reference" autocomplete="off"
                                  name="register_reference" placeholder=""/>
                </b-form-group>
            </b-col>

        </b-row>
        <b-row class="mt-1">
            <b-col>
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="" label-for="type_thing">
                          <b-form-checkbox
                                id="type_thing"
                                v-model="client_filter_data.objective_by_modules"
                                name="type_thing"
                                :value="1"
                                @change="changeObjectiveByModule()"
                            >
                                Objectiva per mòduls
                          </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="" label-for="type_thing">
                          <b-form-checkbox
                                id="type_things"
                                v-model="client_filter_data.direct_simple"
                                name="type_thing"
                                :value="1"
                                @change="checkSimpleDirect()"
                            >
                                Directa simplificada
                          </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <form-epigraphs/>
        <form-taxes/>
        <b-row>
            <b-col v-if="client_filter_data.feasible_town_hall" cols="6">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="engineering_documentation">
                            <b-form-checkbox
                                id="engineering_documentation"
                                v-model="client_filter_data.engineering_documentation"
                                name="engineering_documentation"
                                :value="1"
                            >
                                Projecte d'enginyeria / tècnic
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.engineering_documentation" cols="9">
                        <b-form-group label="" label-for="file_harmless_activity">
                            <b-form-file
                                v-model="client_filter_data.file_engineering_documentation"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        v-if="client_filter_data.engineering_documentation && client_filter_data.file_engineering_documentation
                         && ( typeof client_filter_data.file_engineering_documentation!='object')"
                        cols="3" class="mt-0">
                        <a :href="baseUrl+client_filter_data.file_engineering_documentation" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_engineering_documentation')"
                        />
                    </b-col>
                </b-row>
            </b-col>
            <b-col v-if="client_filter_data.feasible_town_hall || client_filter_data.harmless_activity" cols="6">
                <b-row>
                    <b-col cols="12">
                        <div>
                            Porta menys de 6 mesos amb l'activitat censada?
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <b-form-group label="" label-for="six_months_registered_activity">
                            <div class="">
                                <b-form-radio
                                    v-for="bool in boolean"
                                    :key="bool.name"
                                    v-model="client_filter_data.six_months_registered_activity"
                                    name="six_months_registered_activity"
                                    class="mt-1"
                                    :value="bool.value"
                                >
                                    {{ bool.name }}
                                </b-form-radio>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.six_months_registered_activity" cols="8" class="mt-1">
                        <b-form-group label="" label-for="file_harmless_activity">
                            <b-form-file
                                v-model="client_filter_data.file_six_months_registered_activity"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        v-if="client_filter_data.six_months_registered_activity && client_filter_data.file_six_months_registered_activity
                        && ( typeof client_filter_data.file_six_months_registered_activity!='object')"
                        cols="2" class="mt-0 p-0 mt-1">
                        <a :href="baseUrl+client_filter_data.file_six_months_registered_activity" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_six_months_registered_activity')"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col v-if="(client_filter_data.feasible_town_hall || client_filter_data.harmless_activity)
             && client_filter_data.adaptation_works"
                   cols="6">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="works_invoices">
                            <b-form-checkbox
                                id="works_invoices"
                                v-model="client_filter_data.works_invoices"
                                name="works_invoices"
                                :value="1"
                            >
                                Comunicació previa
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.works_invoices" cols="9">
                        <b-form-group label="" label-for="file_adaptation_works">
                            <b-form-file
                                v-model="client_filter_data.file_works_invoices"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        v-if="client_filter_data.works_invoices && client_filter_data.file_works_invoices
                        && ( typeof client_filter_data.file_works_invoices!='object')"
                        cols="3" class="mt-0">
                        <a :href="baseUrl+client_filter_data.file_works_invoices" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_works_invoices')"
                        />
                    </b-col>
                </b-row>
            </b-col>
            <b-col
                v-if="(client_filter_data.feasible_town_hall || client_filter_data.harmless_activity) && client_filter_data.adaptation_works"
                cols="6">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="" label-for="town_hall_request">
                            <b-form-checkbox
                                id="town_hall_request"
                                v-model="client_filter_data.town_hall_request"
                                name="town_hall_request"
                                :value="1"
                            >
                                Solicituds d'ajuntament
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="client_filter_data.town_hall_request" cols="9">
                        <b-form-group label="" label-for="file_adaptation_works">
                            <b-form-file
                                v-model="client_filter_data.file_town_hall_request"
                                placeholder="Tria un document"
                                drop-placeholder="Tria un document"
                                browse-text="Seleccionar"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        v-if="client_filter_data.town_hall_request && client_filter_data.file_town_hall_request
                        && ( typeof client_filter_data.file_town_hall_request!='object')"
                        cols="3" class="mt-0">
                        <a :href="baseUrl+client_filter_data.file_town_hall_request" target="_blank">
                            <feather-icon
                                icon="EyeIcon"
                                class="cursor-pointer mt-0 "
                                size="25"
                            />
                        </a>
                        <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer text-danger ml-1"
                            size="25"
                            @click="deleteDataFilterDocument('file_town_hall_request')"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div v-if="client_filter_data.incorporated_company">
            <b-col sm="12">
                <h4>Documentació AEAT</h4>
            </b-col>
            <model-036-form/>
            <model-037-form/>
        </div>
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12" class="pb-1 pt-1">
                        <h4>Documentació SS (Seguretat Social)</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="col-md-3">
                        <b-form-group label="Alta autònom" label-for="test">
                            <div class="demo-inline-spacing">
                                <b-form-radio v-for="bool in boolean" :key="bool.name"
                                              v-model="client_filter_data.self_employee"
                                              name="self_employee" class="mt-1" :value="bool.value">
                                    {{ bool.name }}
                                </b-form-radio>
                            </div>
                        </b-form-group>
                    </b-col>

                    <!--                    <b-col cols="6">
                                            <b-form-group label="Documento alta autònom" label-for="file_self_employed">
                                                <b-form-file
                                                    v-model="client_filter_data.file_self_employed"
                                                    placeholder="Tria un document"
                                                    drop-placeholder="Tria un document"
                                                    browse-text="Seleccionar"
                                                />
                                            </b-form-group>
                                        </b-col>
                                        <b-col
                                            v-if="client_filter_data.file_self_employed && ( typeof client_filter_data.file_self_employed!='object')"
                                            cols="2" class="mt-2">
                                            <a :href="baseUrl+client_filter_data.file_self_employed" target="_blank">
                                                <feather-icon
                                                    icon="EyeIcon"
                                                    class="cursor-pointer mt-0 "
                                                    size="25"
                                                />
                                            </a>
                                            <feather-icon
                                                icon="TrashIcon"
                                                class="cursor-pointer text-danger ml-1"
                                                size="25"
                                                @click="deleteDataFilterDocument('file_self_employed')"
                                            />
                                        </b-col>-->
                </b-row>
                <b-row>
                    <b-col cols="5">
                        <b-form-group label="Baixa autònom" label-for="down_self_employed">
                            <div class="demo-inline-spacing">
                                <b-form-radio
                                    v-for="bool in boolean"
                                    :key="bool.name"
                                    v-model="client_filter_data.down_self_employed"
                                    name="down_self_employed"
                                    class="mt-1"
                                    :value="bool.value"
                                >
                                    {{ bool.name }}
                                </b-form-radio>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div class="row">
            <div class="col-md-4">
                <b-form-group label="Alta empleada de la llar i seguiment" label-for="home_employee">
                    <div class="demo-inline-spacing">
                        <b-form-radio v-for="bool in boolean" :key="bool.name"
                                      v-model="client_filter_data.home_employee"
                                      name="home_employee" class="mt-1" :value="bool.value">
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </div>
            <div v-if="client_filter_data.home_employee == 1" class="col-md-3">
                <b-form-group label="Compte bancari" label-for="home_employee_account">
                    <b-form-input v-model="client_filter_data.home_employee_account" autocomplete="off"
                                  name="home_employee_account" placeholder=""/>
                </b-form-group>
            </div>
            <div v-if="client_filter_data.home_employee == 1" class="col-md-3">
                <b-form-group label="DNI emprada llar" label-for="client_dni">
                    <b-form-input v-model="client_filter_data.client_dni" autocomplete="off"
                                  name="client_dni" placeholder=""/>
                </b-form-group>
            </div>
        </div>
        <b-row>
            <b-col cols="12">
                <b-form-group cols="4" label="Alta empresa CCC" label-for="ccc_company">
                    <div class="demo-inline-spacing">
                        <b-form-radio
                            v-for="bool in boolean"
                            :key="bool.name"
                            v-model="client_filter_data.ccc_company"
                            name="ccc_company"
                            class="mt-1"
                            :value="bool.value"
                        >
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="row">
            <div class="col-md-6">
                <b-form-group label="Sol·licitud prestació cessament activitat autònoms"
                              label-for="autonomous_cessation">
                    <div class="demo-inline-spacing">
                        <b-form-radio v-for="bool in boolean" :key="bool.name"
                                      v-model="client_filter_data.autonomous_cessation"
                                      name="autonomous_cessation" class="mt-1" :value="bool.value">
                            {{ bool.name }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </div>
            <div v-if="client_filter_data.autonomous_cessation == 1" class="col-md-6">
                <b-form-group label="Ho tramita vostè?" label-for="autonomous_cessation_itself">
                    <div class="demo-inline-spacing">
                        <b-form-radio v-for="bool in boolean" :key="bool.name"
                                      v-model="client_filter_data.autonomous_cessation_itself"
                                      name="autonomous_cessation_itself" class="mt-1" :value="bool.value">
                            {{ bool.name == 'Si' ? 'Si' : 'No - Vinculada a la data d\'hisenda' }}
                        </b-form-radio>
                    </div>
                </b-form-group>
            </div>
        </div>
        <!--        <b-row>
                    <b-col cols="12">
                        <b-form-group cols="4" label="Sol·licitud capitalització desocupació"
                                      label-for="unemployment_capitalization">
                            <div class="demo-inline-spacing">
                                <b-form-radio
                                    v-for="bool in boolean"
                                    :key="bool.name"
                                    v-model="client_filter_data.unemployment_capitalization"
                                    name="unemployment_capitalization"
                                    class="mt-1"
                                    :value="bool.value"
                                >
                                    {{ bool.name }}
                                </b-form-radio>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>-->
        <b-row align-h="start">
            <b-col cols="5">
                <b-button variant="primary" class="mt-2 mr-1" @click="updateData">
                    Actualitzar canvis
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCard,
    BBadge,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    BRow,
    BFormRadioGroup
} from "bootstrap-vue"
import {typeClients, typeEconomicsActivity} from "@/constants/client"
import {boolean} from "@/constants/constants"
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import {baseUrl} from "@/constants/app"
import Swal from "sweetalert2"
import FormPartners from "@/views/clients/form/components/formPartners"
import FormFamilyPartners from "@/views/clients/form/components/formFamilyPartners"
import FormWorkers from "@/views/clients/form/components/formWorkers"
import FormEpigraphs from "@/views/clients/form/components/formEpigraphs"
import FormTaxes from "@/views/clients/form/components/formTaxes"
import model036Form from "@/views/clients/form/components/models/model036Form"
import model037Form from "@/views/clients/form/components/models/model037Form"
import vSelect from "vue-select"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "FormDataFilterView",
    components: {
        BCard,
        BCol,
        BRow,
        BButton,
        BBadge,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormCheckbox,
        BFormRadioGroup,

        FormPartners,
        FormFamilyPartners,
        FormWorkers,
        FormEpigraphs,
        model036Form,
        model037Form,
        FormTaxes,
        vSelect
    },
    props: ['disabled'],
    data() {
        return {
            users: [],
            edit: false,

            boolean,
            typeClients,
            typeEconomicsActivity,
            baseUrl,
            isChecked: false,
            list: ['Lloguer', 'Propietat'],
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client_filter_data: 'client.client_filter_data',
            client: 'client',
        }),
        quantityPartnersOptions() {
            const options = []
            for (let i = 0; i <= this.client.client_partners.length; i++) {
                options.push({
                    name: i.toString(),
                    value: i
                })
            }
            return options
        }
    },
    watch: {
        'client_filter_data.selected_place': function (changes) {
            if (changes) {
                if (changes == 'Lloguer') {
                    this.client_filter_data.rent_place = 1
                    this.client_filter_data.property_place = 0
                } else if (changes == 'Propietat') {
                    this.client_filter_data.property_place = 1
                    this.client_filter_data.rent_place = 0
                } else {
                    this.client_filter_data.property_place = 0
                    this.client_filter_data.rent_place = 0
                }

            }

        },
    },
    methods: {
        updateData() {
            this.$store.dispatch('client/updateClientFilterData', this.formatData())
            .then(response => {
                this.$parent.getClient()
                this.showToast('Client actualitzat correctament!', 'CheckIcon', 'success')
            })
            .catch(error => {
                this.showToast('Error en actualitzar el client!', 'AlertOctagonIcon', 'danger')
            })
        },
        formatData() {
            var formData = new FormData()

            this.appendFormData(formData, this.client, 'client')
            return formData
        },
        appendFormData(formData, data, rootName) {
            let root = rootName || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.appendFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.appendFormData(formData, data[key], key)
                        } else {
                            this.appendFormData(formData, data[key], `${root}[${key}]`)
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                } else {
                    formData.append(root, '')
                }
            }
        },
        checkHarmlessActivity() {
            if (this.client_filter_data.harmless_activity)
                this.client_filter_data.feasible_town_hall = 0
        },
        checkFeasibleTownHall() {
            if (this.client_filter_data.feasible_town_hall)
                this.client_filter_data.harmless_activity = 0
        },
        changeObjectiveByModule() {
            if (this.client_filter_data.objective_by_modules)
                this.client_filter_data.direct_simple = 0
        },
        checkSimpleDirect() {
            if (this.client_filter_data.direct_simple)
                this.client_filter_data.objective_by_modules = 0
        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
        deleteDataFilterDocument(document) {
            Swal.fire({
                title: "Esteu segur d'esborrar el document?",
                text: "Un cop esborrat no ho podràs recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, esborra'l!",
                cancelButtonText: "Cancel·lar",
            }).then(result => {
                if (result.value) {
                    this.client_filter_data[document] = null
                }
            })

        },
        uncheck: function (val) {
            if (val === this.client_filter_data.selected_place) {
                this.client_filter_data.selected_place = false
                this.client_filter_data.rent_place = 0
                this.client_filter_data.property_place = 0
            } else {
                this.client_filter_data.selected_place = val
            }
        }
    }
}
</script>

<style scoped>
</style>