<template>
  <div>
        <b-button variant="primary" @click="goToCreate()">
          Crear requeriment
        </b-button>
  </div>
</template>

<script>
import {BRow, BCol, BButton, BCard, BBadge, BOverlay} from 'bootstrap-vue'
import vSelect from "vue-select"
import {POLICIES_CREATE, REQUIREMENTS_CREATE} from "@/router/web/constants"

export default {
  name: "CreateRequirementButton",
  components: {
    BRow,
    BCol,
    BButton,

    vSelect,
  },
  data(){
    return{
      pageLength: 10,
    }
  },
  methods:{
    goToCreate() {
      this.$router.push(REQUIREMENTS_CREATE)
    },
    changePage(page) {
      this.page = page
    },
    changeLength(length) {
      this.length = length
    },

  }
}
</script>