<template>
    <div>
        <b-row>
            <b-col cols="8">
                <div class="mb-2">
                    <h4>Filtre</h4>
                </div>
            </b-col>
            <b-col lg="4" md="4" class="text-right">
                <b-button variant="primary" @click="cleanFilters">
                    Netejar filtres
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!this.$route.params.id" cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Client" label-for="Client">
                    <b-form-input v-model="searchFields.client" placeholder="Client" autocomplete="off"
                                  @update="searchScriptures()"/>
                </b-form-group>
            </b-col>
            <b-col v-if="!this.$route.params.id" cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="NIF/CIF" label-for="Client">
                    <b-form-input v-model="searchFields.nif_cif" placeholder="NIF/CIF" autocomplete="off"
                                  @update="searchScriptures()"/>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Tipus" label-for="Tipus">
                    <select v-model="searchFields.type" class="custom-select" @change="searchScriptures()">
                        <option value=""></option>
                        <option v-for="type in scripturesTypes"
                                :key="type.value"
                                :value="type.value"> {{ type.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Categoria" label-for="Categoria">
                    <select v-model="searchFields.category" class="custom-select" @change="searchScriptures()">
                        <option value=""></option>
                        <option v-for="category in scripturesCategories"
                                :key="category.value"
                                :value="category.value"> {{ category.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Notari" label-for="Notari">
                    <b-form-input v-model="searchFields.notary" placeholder="Notari" autocomplete="off"
                                  @update="searchScriptures()"/>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="3" sm="6" xl="2">
                <b-form-group label="Data signatura" label-for="Data signatura">
                    <flat-pickr
                        v-model="searchFields.signature_date"
                        class="form-control"
                        placeholder="Data signatura"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchScriptures()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Protocol" label-for="Protocol">
                    <b-form-input v-model="searchFields.protocol" placeholder="Protocol" autocomplete="off"
                                  @update="searchScriptures()"/>
                </b-form-group>
            </b-col>
            <b-col cols="2" lg="2" md="3" sm="2" xl="2">
                <b-form-group label="Data devengo" label-for="Data devengo">
                    <flat-pickr
                        v-model="searchFields.accrual_date"
                        class="form-control"
                        placeholder="Data devengo"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchScriptures()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="2" lg="2" md="3" sm="2" xl="2">
                <b-form-group label="Data limit" label-for="Data limit">
                    <flat-pickr
                        v-model="searchFields.limit_date"
                        class="form-control"
                        placeholder="Data limit"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchScriptures()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="2" lg="2" md="3" sm="2" xl="2">
                <b-form-group label="Data prescripció" label-for="Data prescripció">
                    <flat-pickr
                        v-model="searchFields.prescription_date"
                        class="form-control"
                        placeholder="Data prescripció"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchScriptures()"
                    />
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12">
                <b-form-group label="Estat" label-for="Estat">
                    <select v-model="searchFields.status" class="custom-select"
                            @change="searchScriptures()">
                        <option value=""></option>
                        <option v-for="status in scripturesStatus"
                                :key="status.value"
                                :value="status.value"> {{ status.name.toUpperCase() }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup,
    BIcon,
    BInputGroupText,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import TableScriptures from "@/views/trading/scriptures/list/components/tableScriptures"
import CreateScriptureButton from "@/views/trading/scriptures/list/components/createScriptureButton"
import {createHelpers} from "vuex-map-fields"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"
import {scripturesCategories, scripturesTypes, scripturesStatus} from "@/constants/trading"

const {mapFields} = createHelpers({
    getterType: 'scriptures/getField',
    mutationType: 'scriptures/updateField',
})

export default {
    name: "FilterScriptures",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        BIcon,
        BInputGroupText,

        vSelect,
        flatPickr,

        TableScriptures,
        CreateScriptureButton,

        scripturesCategories,
        scripturesTypes,
        scripturesStatus

    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            showLoading: false,
            searchFields: [],
            searchConfig: [],

            CatalanLocale,
            scripturesCategories,
            scripturesTypes,
            scripturesStatus
        }
    },
    watch: {
        totalRecords (currentValue) {
            this.$emit('update', currentValue);
        }
    },
    beforeMount() {
        this.$store.commit('scriptures/RESET_STATE')
        this.$root.$on('callGetScriptures', () => {
            this.getScriptures()
        })
        this.getScriptures()
    },
    methods: {
        searchScriptures() {
            this.getScriptures()
        },
        cleanFilters() {
            this.searchFields = []
            this.getScriptures()
        },
        getScriptures() {
            this.showLoading = true
            this.searchConfig = [
                {
                    'type': 'concat_strings',
                    'concat_fields': ['clients.name', 'clients.first_surname', 'clients.second_surname'],
                    'searchTerm': this.searchFields.client ?? ''
                },
                {'type': 'string', 'column': 'clients.nif_cif', 'searchTerm': this.searchFields.nif_cif ?? ''},
                {'type': 'string', 'column': 'scriptures.type', 'searchTerm': this.searchFields.type ?? ''},
                {'type': 'string', 'column': 'category', 'searchTerm': this.searchFields.category ?? ''},
                {'type': 'string', 'column': 'notary', 'searchTerm': this.searchFields.notary ?? ''},
                {'type': 'string', 'column': 'protocol', 'searchTerm': this.searchFields.protocol ?? ''},
                {'type': 'string', 'column': 'accrual_date', 'searchTerm': this.searchFields.accrual_date ?? ''},
                {'type': 'string', 'column': 'limit_date', 'searchTerm': this.searchFields.limit_date ?? ''},
                {'type': 'string', 'column': 'prescription_date', 'searchTerm': this.searchFields.prescription_date ?? ''},
                {'type': 'string', 'column': 'signature_date', 'searchTerm': this.searchFields.signature_date ?? ''},
                {'type': 'string', 'column': 'status', 'searchTerm': this.searchFields.status ?? ''},
            ]

            this.$store.dispatch('scriptures/searchScriptures', {
                client_id: this.$route.params.id ?? '',
                per_page: this.pageLength,
                page: this.page,
                filter: this.searchConfig.length > 0 ? this.searchConfig : '',
                relations: ['client']
            })
            .then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records
                this.$emit('update',this.totalRecords)
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>