<template>
    <div>
        <b-row>
            <b-col cols="8">
                <div class="mb-2">
                    <h4>Filtre</h4>
                </div>
            </b-col>
            <b-col lg="4" md="4" class="text-right">
                <b-button variant="primary" @click="cleanFilters">
                    Netejar filtres
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!this.$route.params.id" cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Client" label-for="Client">
                    <b-form-input v-model="searchFields.client" placeholder="Client" autocomplete="off"
                                  @update="searchRequirements()"/>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Data recepció client" label-for="Data recepció client">
                    <flat-pickr
                        v-model="searchFields.date_received_client"
                        class="form-control"
                        placeholder="Data recepció client"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchRequirements()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Data venciment" label-for="Data venciment">
                    <flat-pickr
                        v-model="searchFields.date_expiration"
                        class="form-control"
                        placeholder="Data venciment"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchRequirements()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="3">
                <b-form-group label="Data venciment amb ampliació" label-for="Data venciment ampliacio">
                    <flat-pickr
                        v-model="searchFields.date_expiration_added_days"
                        class="form-control"
                        placeholder="Data venciment amb ampliació"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y', defaultDate:0}"
                        @on-change="searchRequirements()"
                    />
                </b-form-group>
            </b-col>

            <b-col lg="2" md="12">
                <b-form-group label="Periode" label-for="Periode">
                    <select v-model="searchFields.trimester" class="custom-select" @change="searchRequirements()">
                        <option value=""></option>
                        <option v-for="trimester in trimesters"
                                :key="trimester.value"
                                :value="trimester.value"> {{ trimester.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12">
                <b-form-group label="Estat" label-for="Estat">
                    <select v-model="searchFields.status" class="custom-select"
                             @change="searchRequirements()">
                        <option value=""></option>
                        <option v-for="status in requirementsStatus"
                                :key="status.id"
                                :value="status.id"> {{ status.name.toUpperCase() }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col cols="6" lg="3" md="4" sm="6" xl="2">
                <b-form-group label="Tipus de requeriment" label-for="Descripció">
                    <select v-model="searchFields.type" class="custom-select" @change="searchRequirements()">
                        <option value=""></option>
                        <option v-for="tax in this.taxesTypes"
                                :key="tax.value"
                                :value="tax.id"> {{ tax.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup,
    BIcon,
    BInputGroupText,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import TableRequirements from "@/views/accounting/requirements/list/components/tableRequirements"
import CreateRequirementButton from "@/views/accounting/requirements/list/components/createRequirementButton"
import {createHelpers} from "vuex-map-fields"
import {requirementsStatus, trimesters} from "@/constants/accounting"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"

const {mapFields} = createHelpers({
    getterType: 'requirements/getField',
    mutationType: 'requirements/updateField',
})

export default {
    name: "FilterRequirements",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        BIcon,
        BInputGroupText,

        vSelect,
        flatPickr,

        TableRequirements,
        CreateRequirementButton

    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            showLoading: false,
            searchFields: [],
            searchConfig: [],

            requirementsStatus,
            trimesters,
            taxesTypes: [],

            CatalanLocale
        }
    },
    mounted() {
        this.$store.commit('requirements/RESET_STATE')
        this.$root.$on('callGetRequirements', () => {
            this.getRequirements()
        })
        this.getRequirements()
        this.getTaxesTypes()
    },
    methods: {
        searchRequirements() {
            this.getRequirements()
        },
        cleanFilters() {
            this.searchFields = []
            this.getRequirements()
        },
        getRequirements() {
            this.showLoading = true
            this.searchConfig = [
                {
                    'type': 'concat_strings',
                    'concat_fields': ['clients.name', 'clients.first_surname', 'clients.second_surname'],
                    'searchTerm': this.searchFields.client ?? ''
                },
                {'type': 'string', 'column': 'trimester', 'searchTerm': this.searchFields.trimester ?? ''},
                {'type': 'string', 'column': 'date_expiration_added_days', 'searchTerm': this.searchFields.date_expiration_added_days ?? ''},
                {'type': 'string', 'column': 'status', 'searchTerm': this.searchFields.status ?? ''},
                {'type': 'string', 'column': 'taxes.id', 'searchTerm': this.searchFields.type ?? ''},
            ]

            this.$store.dispatch('requirements/searchRequirements', {
                client_id: this.$route.params.id ?? '',
                per_page: this.pageLength,
                page: this.page,
                filter: this.searchConfig.length > 0 ? this.searchConfig : '',
                relations: ['client', 'taxesTypes']
            })
            .then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records
                this.$emit('update',this.totalRecords)
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
        getTaxesTypes() {
            this.showLoading = true

            this.$store.dispatch('requirements/getTaxesTypes')
            .then(response => {
                this.taxesTypes = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.background-in_progress {
    color: #ffc107;
    border: 1px solid #ffc107;
}

.background-accepted {
    color: #28c76f;
    border: 1px solid #28c76f;
}

.background-sent_to_client {
    color: #00cfe8;
    border: 1px solid #00cfe8;
}

.background-closed {
    color: #82868b;
    border: 1px solid #82868b;
}

.background-denied {
    color: #ea5455;
    border: 1px solid #ea5455;
}

select option[value="in_progress"] {
    color: #ffc107 !important;
    background: rgba(255, 159, 67, 0.12);
}

select option[value="accepted"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}

select option[value="sent_to_client"] {
    color: #00cfe8 !important;
    background: rgba(0, 207, 232, 0.12);
}

select option[value="closed"] {
    color: #82868b !important;
    background: rgba(130, 134, 139, 0.12);
}

select option[value="denied"] {
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.12);
}
</style>