<template>
    <div>

        <b-table
            ref="refPolicyListTable"
            class="position-relative table-policies"
            responsive
            primary-key="id"
            show-empty
            empty-text="No s'han trobat pòlisses"
            :items="policies"
            :fields="columns"
        >
            <template #cell(status)="data">
                <span
                    :class="['text-uppercase badge badge-pill badge-light-'+getStatusColor(data.item.status) ]"> {{ data.item.status }} </span>
            </template>

            <template #cell(client)="data">
                <router-link v-if="currentRouteName !== 'clients_edit'"
                             :to="{ name: 'clients_edit', params: { id: data.item.client_id, activeTab: 'policies'} }">
                    {{ data.item.client }}
                </router-link>
                <div v-else> {{ data.item.client }}</div>
            </template>

            <template #cell(value)="data">
                <span>
              <money style="border-style: hidden" readonly="true" v-model="data.item.value"
                     v-bind="money">{{ data.item.value }}</money>
                </span>
            </template>

            <template #cell(actions)="data">
                <div>
                    <router-link v-show="data.item.id" :to="{ name: 'policies_view', params: { id: data.item.id} }">
                        <feather-icon
                            v-if="can('view_policies')"
                            :id="`invoice-row-${data.item.id}-send-icon`"
                            icon="EyeIcon"
                            class="mr-1 cursor-pointer"
                            size="16"
                        />
                    </router-link>

                    <router-link v-show="data.item.id" :to="{ name: 'policies_edit', params: { id: data.item.id} }">
                        <feather-icon
                            v-if="can('edit_policies')"
                            :id="`invoice-row-${data.item.id}-send-icon`"
                            icon="EditIcon"
                            class="cursor-pointer"
                            size="16"
                        />
                    </router-link>

                    <feather-icon
                        v-if="can('delete_policies')"
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer mx-1 text-red"
                        @click="deletePolicy(data.item.id)"
                    />
                </div>
            </template>

        </b-table>

    </div>
</template>


<script>
import { BTable } from 'bootstrap-vue'
import {mapState} from "vuex"
import {policiesPeriods, insurancesTypes} from "@/constants/insurancePolicies"
import Swal from "sweetalert2"

export default {
    name: "TablePolicies",
    components: {
        BTable,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            policiesPeriods,
            insurancesTypes,

            columns: [
                {key: 'company', label: 'Cia'},
                {key: 'policy_number', label: 'Pòlissa', sortable: false},
                {key: 'insurance_type', label: 'Ram', sortable: false},
                {key: 'risk', label: 'Risc', sortable: false},
                {key: 'date_first_effect', label: 'Primer efecte', sortable: false},
                {key: 'date_effect', label: 'Data efecte', sortable: false},
                {key: 'date_expiration', label: 'Data venciment', sortable: false},
                {key: 'client', label: 'Client', sortable: false},
                {key: 'value', label: 'Valor pòlissa', sortable: false},
                {key: 'period', label: 'Període', sortable: false},
                {key: 'year', label: 'Any', sortable: false},
                {key: 'status', label: 'Estat', sortable: false},
                {key: 'actions', label: 'Accions', sortable: false},
            ]
        }
    },
    computed: {
        ...mapState('insurancePolicies', ['policies']),
        currentRouteName() {
            return this.$route.name;
        }
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        getStatusColor: function (status) {
            switch (status) {
                case 'En curs':
                    return 'warning'
                case 'Enviat':
                    return 'info'
                case 'Acceptat':
                    return 'success'
                case 'Anul·lada':
                    return 'secondary'
                case 'Denegada':
                    return 'danger'
            }
        },
        deletePolicy(id) {
            Swal.fire({
                title: "Esteu segur d'esborrar la pòlissa?",
                text: "Un cop esborrat no ho podràs recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, esborra'l!",
                cancelButtonText: "Cancel·lar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('insurancePolicies/deletePolicy', {id: id})
                        .then(response => {
                            this.showLoading = false
                            this.$router.go()
                        })
                        .catch(error => {
                            this.showLoading = false
                            console.log(error)
                        })
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir] .table-policies tr:last-child {
    font-weight: bold;
    background: rgba(167, 227, 208, 0.3)
}
</style>