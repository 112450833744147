export const typeClients = [
    {value: 'physical_person', name: 'Persona Física'},
    {value: 'legal_person', name: 'Persona Jurídica'},
]

export const typeEconomicsActivity = [
    {value: 'business_activity', name: 'Activitat Empresarial'},
    {value: 'professional_activity', name: 'Activitat Profesional'},
    {value: 'property_trends', name: 'Mera tendencia de bienes'},
    {value: 'doesnt_have', name: 'No disposa'},
]