<template>
    <div>
        <b-row>
            <b-col sm="12" class="mt-1">
                <h4>Impostos</h4>
            </b-col>
            <b-col sm="3" class="mt-1">
                <h6>Mensuals</h6>
                <b-form-group class="mb-10"
                              v-slot="{ ariaDescribedby }"
                >
                    <b-form-checkbox
                        v-for="optionMonth in optionsMonthly"
                        :value="1"
                        :key="optionMonth.value"
                        v-model="client_filter_data[optionMonth.value]"
                        unchecked-value="0"
                        class="mt-1"
                        :disabled="readOnly"
                    >
                        {{ optionMonth.text }}
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-1">
                <h6>Trimestrals</h6>
                <b-form-group class="mb-10"
                              v-slot="{ ariaDescribedby }"
                >
                    <b-form-checkbox
                        v-for="optionTrimester in optionsTrimester"
                        v-model="client_filter_data[optionTrimester.value]"
                        :value="1"
                        :key="optionTrimester.value"
                        unchecked-value="0"
                        class="mt-1"
                        :disabled="readOnly"
                    >
                        {{ optionTrimester.text }}
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-1">
                <h6>Anuals</h6>
                <b-form-group class="mb-10"
                              v-slot="{ ariaDescribedby }"
                >
                    <b-form-checkbox
                        v-for="optionAnnual in optionsAnnual"
                        v-model="client_filter_data[optionAnnual.value]"
                        :value="1"
                        :key="optionAnnual.value"
                        unchecked-value="0"
                        class="mt-1"
                        :disabled="readOnly"
                    >
                        {{ optionAnnual.text }}
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BCol, BFormGroup, BRow, BFormCheckbox} from "bootstrap-vue"
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import vSelect from "vue-select"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    props: ['readOnly'],
    name: "FormTaxes",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormCheckbox,

        vSelect,
    },
    data() {
        return {
            selectedTaxesMonthly: [],
            optionsMonthly: [
                { text: 'Model 111', value: 'model_monthly_111' },
                { text: 'Model 115', value: 'model_monthly_115' },
                { text: 'Model 123', value: 'model_monthly_123' },
                { text: 'Model 340', value: 'model_monthly_340' },
                { text: 'Model 303', value: 'model_monthly_303' },
                { text: 'Model 349', value: 'model_monthly_349' }
            ],

            selectedTaxesTrimester: [],
            optionsTrimester: [
                { text: 'Model 111', value: 'model_trimester_111' },
                { text: 'Model 115', value: 'model_trimester_115' },
                { text: 'Model 123', value: 'model_trimester_123' },
                { text: 'Model 130', value: 'model_trimester_130' },
                { text: 'Model 131', value: 'model_trimester_131' },
                { text: 'Model 202', value: 'model_trimester_202' },
                { text: 'Model 303', value: 'model_trimester_303' },
                { text: 'Model 349', value: 'model_trimester_349' },
            ],

            selectedTaxesAnnual: [],
            optionsAnnual: [
                { text: 'Model 180', value: 'model_annual_180' },
                { text: 'Model 182', value: 'model_annual_182' },
                { text: 'Model 184', value: 'model_annual_184' },
                { text: 'Model 190', value: 'model_annual_190' },
                { text: 'Model 193', value: 'model_annual_193' },
                { text: 'Model 200', value: 'model_annual_200' },
                { text: 'Model 210', value: 'model_annual_210' },
                { text: 'Model 347', value: 'model_annual_347' },
                { text: 'Model 390', value: 'model_annual_390' },
                { text: 'Model 720', value: 'model_annual_720' }
            ],

        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client: 'client',
            client_filter_data: 'client.client_filter_data',
        }),
    },
}
</script>
