<template v-if="client">
    <div>
        <!--        <b-row>
                    <b-col xl="12">
                        <b-form-group label="Tipus de client" label-for="account-username" >
                            <div class="demo-inline-spacing">
                                <b-form-radio
                                    v-model="client.type"
                                    name="radio"
                                    class="mt-1"
                                    value="physical_person"
                                >
                                    Persona Física
                                </b-form-radio>
                                <b-form-radio
                                    v-model="client.type"
                                    name="radio"
                                    class="mt-1"
                                    value="legal_person"
                                >
                                    Persona Jurídica
                                </b-form-radio>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>-->
        <b-row>
            <b-col sm="4">
                <b-form-group label="Nom" label-for="account-username">
                    <b-form-input v-model="client.name" placeholder="Nom" :disabled="disabled"
                                  :class="validation_client.name ? 'is-invalid': ''"/>
                    <small v-if="validation_client.name" class="text-danger">
                        {{ validation_client.name }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Primer cognom" label-for="account-surnames">
                    <b-form-input v-model="client.first_surname" placeholder="Cognoms" :disabled="disabled"
                                  :class="validation_client.first_surname ? 'is-invalid': ''"/>
                    <small v-if="validation_client.first_surname" class="text-danger">
                        {{ validation_client.first_surname }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group label="Segon cognom" label-for="account-surnames">
                    <b-form-input v-model="client.second_surname" placeholder="Cognoms" :disabled="disabled"
                                  :class="validation_client.second_surname ? 'is-invalid': ''"/>
                    <small v-if="validation_client.second_surname" class="text-danger">
                        {{ validation_client.second_surname }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="NIF/CIF" label-for="nienif">
                    <b-form-input v-model="client.nif_cif" placeholder="NIF/CIF" :disabled="disabled"
                                  :class="validation_client.nif_cif ? 'is-invalid': ''"/>
                    <small v-if="validation_client.nif_cif" class="text-danger">
                        {{ validation_client.nif_cif }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Telèfon" label-for="phone">
                    <b-form-input v-model="client.phone" placeholder="Telèfon" :disabled="disabled"
                                  :class="validation_client.phone ? 'is-invalid': ''"/>
                    <small v-if="validation_client.phone" class="text-danger">
                        {{ validation_client.phone }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="4" md="12" sm="12">
                <b-form-group label="Email" label-for="email">
                    <b-form-input v-model="client.email" placeholder="Email" :disabled="disabled"
                                  :class="validation_client.email ? 'is-invalid': ''"/>
                    <small v-if="validation_client.email" class="text-danger">
                        {{ validation_client.email }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="4" md="12" sm="12">
                <b-form-group label="Responsable" label-for="value">
                    <v-select
                        v-model="client.user_id"
                        :options="users"
                        label="name"
                        :reduce="(option) => option.id"
                        :disabled="disabled"
                    >
                        <template #no-options="{ }">
                            No s'han pogut carregar els usuaris
                        </template>
                        <small v-if="validation_client.user_id" class="text-danger">
                            {{ validation_client.user_id }}
                        </small>
                    </v-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col lg="6" md="12" sm="12">
                <b-form-group label="Direcció postal" label-for="address">

                    <b-form-input v-model="client.address" placeholder="Direcció" :disabled="disabled"
                                  :class="validation_client.address ? 'is-invalid': ''"/>
                    <small v-if="validation_client.address" class="text-danger">
                        {{ validation_client.address }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Codi postal" label-for="account-surnames">
                    <b-form-input v-model="client.postal_code" placeholder="Codi postal" :disabled="disabled"
                                  :class="validation_client.postal_code ? 'is-invalid': ''"/>
                    <small v-if="validation_client.postal_code" class="text-danger">
                        {{ validation_client.postal_code }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="4" md="12" sm="12">
                <b-form-group label="Població" label-for="address">
                    <b-form-input v-model="client.city" placeholder="Població" :disabled="disabled"
                                  :class="validation_client.city ? 'is-invalid': ''"/>
                    <small v-if="validation_client.city" class="text-danger">
                        {{ validation_client.city }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {BCol, BFormGroup, BFormInput, BRow} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {typeClients, typeEconomicsActivity} from "@/constants/client"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "FormDataClient",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,

        vSelect,
    },
    props: ['client', 'disabled'],
    data() {
        return {
            users: [],
            edit: false,

            typeClients,
            typeEconomicsActivity,
            SpanishLocale,
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client_personal_data: 'client_personal_data'
        }),
    },
    beforeMount() {
        if (!this.client) {
            this.client = this.$store.state.client.client
        }
    },
    created() {
        this.getUsers()
    },
    methods: {
        getUsers() {
            this.showLoading = true

            this.$store.dispatch('user/getAllUsers', {
                id: this.$route.params.id,
            })
            .then(response => {
                this.users = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>