<template>
    <b-row>
        <b-col sm="12" class="pb-1 pt-1">
            <h4>Socis</h4>
        </b-col>
        <b-row class="col-sm-12" v-for="(client_partner, index) in this.client_partners"
               :key="client_partner.id">
            <b-col sm="4">
                <b-form-group label="Nom complet" label-for="name">
                    <b-form-input v-model="client_partner.name" name="name" autocomplete="off"
                                  placeholder="Nom"/>
                </b-form-group>
            </b-col>
            <!--                <b-col sm="3">
                                <b-form-group label="Primer cognom" label-for="first_surname">
                                    <b-form-input v-model="client_partner.first_surname" autocomplete="off"
                                                  name="first_surname" placeholder="Primer cognom"/>
                                </b-form-group>
                            </b-col>
                            <b-col sm="3">
                                <b-form-group label="Segon cognom" label-for="second_surname">
                                    <b-form-input v-model="client_partner.second_surname" autocomplete="off"
                                                  name="second_surname" placeholder="Segon cognom"/>
                                </b-form-group>
                            </b-col>-->
            <b-col sm="3">
                <b-form-group label="DNI" label-for="dni">
                    <b-form-input v-model="client_partner.dni" autocomplete="off"
                                  name="dni" placeholder="DNI"/>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group label="DNI document" label-for="dni">
                    <b-form-file
                        v-model="client_partner.dni_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="2" >
                <a v-if="client_partner.dni_document" :href="baseUrl+client_partner.dni_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
                <feather-icon
                    icon="TrashIcon"
                    variant="outline-danger"
                    class="cursor-pointer mt-0 mt-md-2 ml-2"
                    @click="removePartner(index, client_partner.id)"
                    size="25"
                />
            </b-col>
        </b-row>
        <b-row class="col-sm-12">
            <b-col class="pb-2">
                <b-button variant="" @click="addNewRow()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir Socio</span>
                </b-button>
            </b-col>
        </b-row>
    </b-row>
</template>

<script>
import {BCard, BCol, BButton, BFormGroup, BFormInput, BFormRadio, BFormCheckbox, BFormFile, BRow} from "bootstrap-vue"
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {baseUrl} from "@/constants/app"
import Swal from "sweetalert2"
import helpers from "@/resources/helpers/helpers"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "FormPartners",
    components: {
        BCard,
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormCheckbox,
    },
    data() {
        return {
            baseUrl
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client_filter_data: 'client.client_filter_data',
            client_partners: 'client.client_partners',
            client: 'client'
        }),
    },
    methods: {
        addNewRow() {
            this.client.client_partners.push({
                name: null,
                first_surname: null,
                second_surname: null,
                dni: null,
                dni_document: null,
                dni_document_file: null,
            })
        },
        removePartner(index, partnerId) {
            if (partnerId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('client/deleteClientPartner', partnerId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Soci eliminado correctament', 'CheckIcon', 'success')
                            this.client.client_partners.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar soci', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.client.client_partners.splice(index, 1)
            }
        },
    }
}
</script>

<style scoped>
</style>