<template>
    <b-card title="Treballadors" border-variant="primary" class="text-left mt-2">
        <b-row class="col-sm-12" v-for="(client_worker, index) in this.client_workers"
               :key="client_worker.id">
            <b-col sm="4">
                <b-form-group label="Nom complet" label-for="name">
                    <b-form-input v-model="client_worker.name" name="name" autocomplete="off"
                                  placeholder="Nom"/>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="DNI" label-for="dni">
                    <b-form-input v-model="client_worker.dni" autocomplete="off"
                                  name="dni" placeholder="DNI"/>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group label="DNI document" label-for="dni">
                    <b-form-file
                        v-model="client_worker.dni_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1" v-if="client_worker.dni_document">
                <a :href="baseUrl+client_worker.dni_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>


            <b-col sm="3">
                <b-form-group label="PRL" label-for="prl">
                    <b-form-file
                        v-model="client_worker.prl_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1"
                   v-if="client_worker.prl_document">
                <a :href="baseUrl+client_worker.prl_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>

            <b-col sm="3">
                <b-form-group label="Protocol assetjo" label-for="coexistence_certificate">
                    <b-form-file
                        v-model="client_worker.harassment_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1"
                   v-if="client_worker.harassment_document">
                <a :href="baseUrl+client_worker.harassment_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>

            <b-col sm="3">
                <b-form-group label="Prima conveni" label-for="coexistence_certificate">
                    <b-form-file
                        v-model="client_worker.agreement_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1"
                   v-if="client_worker.agreement_document">
                <a :href="baseUrl+client_worker.agreement_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>
            <b-col sm="3">
                <b-form-group label="Seguro conveni" label-for="conveni">
                    <b-form-file
                        v-model="client_worker.insurance_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1"
                   v-if="client_worker.insurance_document">
                <a :href="baseUrl+client_worker.insurance_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>
            <b-col sm="3">
                <b-form-group label="Llei de protecció de dades" label-for="coexistence_certificate">
                    <b-form-file
                        v-model="client_worker.protection_document_file"
                        placeholder="Tria un document"
                        drop-placeholder="Tria un document"
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1"
                   v-if="client_worker.protection_document">
                <a :href="baseUrl+client_worker.protection_document" target="_blank">
                    <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer mt-0 mt-md-2"
                        size="25"
                    />
                </a>
            </b-col>
            <b-col sm="12" class="d-flex justify-content-end">
                <b-button
                    variant="outline-danger"
                    class="mt-0"
                    @click="removeWorker(index, client_worker.id)"
                >
                    <feather-icon
                        icon="XIcon"
                        class="mr-25"
                    />
                    <span>Esborrar treballador</span>
                </b-button>
            </b-col>
            <hr class="alert-primary">
        </b-row>

        <b-row class="col-sm-12">
            <b-col class="pb-2">
                <b-button variant="" @click="addNewRow()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir treballador</span>
                </b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {BCard, BCol, BButton, BFormGroup, BFormInput, BFormRadio, BFormCheckbox, BFormFile, BRow} from "bootstrap-vue"
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import {baseUrl} from "@/constants/app"
import Swal from "sweetalert2"
import helpers from "@/resources/helpers/helpers"
import {boolean} from "@/constants/constants"
import vSelect from "vue-select"

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "FormFamilyPartners",
    components: {
        BCard,
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormFile,
        BFormCheckbox,
        vSelect
    },
    data() {
        return {
            baseUrl,
            boolean
        }
    },
    computed: {
        ...mapState('client', ['validation_client']),
        ...mapFields({
            client_filter_data: 'client.client_filter_data',
            client_workers: 'client.client_workers',
            client: 'client'
        }),
    },
    methods: {
        addNewRow() {
            this.client.client_workers.push({
                name: null,
                dni: null,
                dni_document: null,
                dni_document_file: null,
                prl_document_file: null,
                prl_document: null,
                harassment_document_file: null,
                harassment_document: null,
                agreement_document_file: null,
                agreement_document: null,
                insurance_document_file: null,
                insurance_document: null,
                protection_document_file: null,
                protection_document: null,
            })
        },
        removeWorker(index, workerId) {
            if (workerId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el registre?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('client/deleteClientWorker', workerId)
                        .then(response => {
                            this.showLoading = false
                            helpers.showToast('Treballador eliminado correctament', 'CheckIcon', 'success')
                            this.client.client_workers.splice(index, 1)
                        })
                        .catch(error => {
                            this.showLoading = false
                            helpers.showToast('Error en eliminar familiar treballador', 'CheckIcon', 'Alert')
                        })
                    }
                })
            } else {
                this.client.client_workers.splice(index, 1)
            }
        },
    }
}
</script>

<style scoped>
hr {
    display: block;
    width: 100%;
    height: 1px;
}
</style>