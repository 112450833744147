<template>
    <div>

        <b-table
            ref="refRequirementListTable"
            class="position-relative"
            responsive
            primary-key="id"
            show-empty
            empty-text="No s'han trobat requeriments"
            :items="requirements"
            :fields="columns"
        >
            <template #cell(status)="data">
                <span
                    :class="['text-uppercase badge badge-pill badge-light-'+getStatusColor(data.item.status) ]"> {{
                        data.item.status
                    }} </span>
            </template>

            <template #cell(client.name)="data">
                <router-link v-if="currentRouteName !== 'clients_edit'"
                             :to="{ name: 'clients_edit', params: { id: data.item.client_id, activeTab: 'requirements'} }">
                    {{ data.item.client.name }}  {{ data.item.client.first_surname }}  {{ data.item.client.second_surname }}
                </router-link>
                <div v-else> {{ data.item.client.name }}  {{ data.item.client.first_surname }}  {{ data.item.client.second_surname }}</div>
            </template>

            <template #cell(trimester)="data">
                {{ data.item.trimester ? trimesters.find(element => element.value === data.item.trimester).name : ''}}
            </template>

            <template #cell(taxes_types)="data">
                <b-badge v-for="tax in data.item.taxes_types" :key="tax.id" variant="primary">
                    {{ tax.name }}
                </b-badge>
            </template>

            <template #cell(status)="data">
                <b-badge class="text-uppercase" :variant="requirementsStatus.find(element => element.id == data.item.status) ? requirementsStatus.find(element => element.id == data.item.status).class : 'default'">
                        {{ data.item.status ? requirementsStatus.find(element => element.id == data.item.status).name : ''}}
                </b-badge>
            </template>

            <template #cell(date_received_client)="data">
                {{helpers.formatDate(data.item.date_received_client)}}
            </template>

            <template #cell(date_expiration)="data">
                {{helpers.formatDate(data.item.date_expiration)}}
            </template>

            <template #cell(date_expiration_added_days)="data">
                {{helpers.formatDate(data.item.date_expiration_added_days)}}
            </template>


            <template #cell(actions)="data">
                <div>
                    <router-link v-show="data.item.id" :to="{ name: 'requirements_edit', params: { id: data.item.id} }">
                        <feather-icon
                            v-if="can('edit_requirements')"
                            :id="`invoice-row-${data.item.id}-send-icon`"
                            icon="EditIcon"
                            class="cursor-pointer"
                            size="16"
                        />
                    </router-link>

                    <feather-icon
                        v-if="can('delete_requirements')"
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer mx-1 text-red"
                        @click="deleteRequirement(data.item.id)"
                    />
                </div>
            </template>
        </b-table>

    </div>
</template>


<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup
} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import Swal from "sweetalert2"
import {trimesters, requirementsStatus} from "@/constants/accounting"
import helpers from "@/resources/helpers/helpers"

export default {
    name: "TableRequirements",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        vSelect,
    },
    data() {
        return {
            page: 1,
            pageLength: 5,
            totalRecords: 10,

            taxesTypes: [],

            columns: [
                {key: 'client.name', label: 'Client', sortable: true},
                {key: 'date_received_client', label: 'Data recepció client', sortable: true},
                {key: 'date_expiration', label: 'Data venciment', sortable: true},
                {key: 'date_expiration_added_days', label: 'Data venciment amb ampliació', sortable: true},
                {key: 'trimester', label: 'Periode', sortable: false},
                {key: 'taxes_types', label: 'Tipus', sortable: false},
                {key: 'status', label: 'Estat', sortable: false},
                {key: 'actions', label: 'Accions', sortable: false},
            ],

            trimesters,
            requirementsStatus,
            helpers
        }
    },
    computed: {
        ...mapState('requirements', ['requirements']),
        currentRouteName() {
            return this.$route.name
        }
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        getStatusColor: function (status) {
            switch (status) {
                case 'En curs':
                    return 'warning'
                case 'Enviat':
                    return 'info'
                case 'Acceptat':
                    return 'success'
                case 'Tancat':
                    return 'secondary'
                case 'Denegada':
                    return 'danger'
            }
        },
        deleteRequirement(id) {
            Swal.fire({
                title: "Esteu segur d'esborrar el requeriment?",
                text: "Un cop esborrat no ho podràs recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, esborra'l!",
                cancelButtonText: "Cancel·lar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('requirements/deleteRequirement', {id: id})
                    .then(response => {
                        this.showLoading = false
                        this.$root.$emit('callGetRequirements')
                    })
                    .catch(error => {
                        this.showLoading = false
                        console.log(error)
                    })
                }
            })
        },
        getTaxesTypes() {
            this.showLoading = true

            this.$store.dispatch('requirements/getTaxesTypes')
            .then(response => {
                this.taxesTypes = response
                this.showLoading = false
            })
            .catch(error => {
                console.log(error)
                //  this.showToast('Error en obtenir tipus d\'impostos', 'AlertOctagonIcon', 'danger')
                this.showLoading = false
            })
        },
    }
}
</script>