<template>
        <b-card title="">
            <b-overlay
                variant="white"
                :show="showLoading"
                spinner-variant="primary"
                blur="0"
                opacity=".75"
                rounded="sm"
            >
                <filter-formalities  ref="FilterFormalities" @update="totalRecords = $event"/>
                <hr>
                <b-row>
                    <b-col cols="12" md="9"
                           class="d-flex align-items-center justify-content-start mb-1"
                    >
                        <label>Mostrar</label>
                        <v-select
                            v-model="pageLength"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="['3','5','10','20','50']"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="changeLength"
                        />
                    </b-col>
                </b-row>
                <table-formalities/>
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">Mostrant {{ pageLength }} de {{ totalRecords }} laboral</span>
                        </b-col>

                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                :value="1"
                                :total-rows="totalRecords"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="changePage"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-overlay>
        </b-card>
</template>

<script>
import {BRow, BCol, BTable, BCard, BBadge, BButton, BTooltip, BPagination, BFormInput, BOverlay} from 'bootstrap-vue'
import vSelect from "vue-select"
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import FilterFormalities from "@/views/labor/formalities/list/components/filterFormalitiesView"
import TableFormalities from "@/views/labor/formalities/list/components/tableFormalities"

const {mapFields} = createHelpers({
    getterType: 'formalities/getField',
    mutationType: 'formalities/updateField',
})
export default {
    name: "ListFormalitiesClient",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,

        vSelect,

        FilterFormalities,
        TableFormalities,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            searchTerm: '',
            showLoading: false,
        }
    },
    computed: {
        ...mapState('formalities', ['formalities']),
        ...mapFields({
            formalities: 'formalities',
        }),
    },
    created() {
        this.$store.commit('formalities/RESET_STATE')
    },
    methods: {
        changePage(page) {
            this.page = page
            this.$refs.FilterFormalities.page = page
            this.$refs.FilterFormalities.getFormalities()
        },
        changeLength(length) {
            this.$refs.FilterFormalities.pageLength = length
            this.$refs.FilterFormalities.getFormalities()
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>