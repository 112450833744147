<template>
    <div>

        <b-table
            ref="refFormalityListTable"
            class="position-relative table-formalities"
            responsive
            primary-key="id"
            show-empty
            empty-text="No s'han trobat tràmits"
            :items="formalities"
            :fields="columns"
        >

            <template #cell(client)="data">
                <router-link v-if="currentRouteName !== 'clients_edit'"
                             :to="{ name: 'clients_edit', params: { id: data.item.client_id, activeTab: 'requirements'} }">
                    {{ data.item.client.name }}  {{ data.item.client.first_surname }}  {{ data.item.client.second_surname }}
                </router-link>
                <div v-else> {{ data.item.client.name }}  {{ data.item.client.first_surname }}  {{ data.item.client.second_surname }}</div>
            </template>

            <template #cell(type)="data">
                {{formalityTypes.find(element => element.value ===data.item.type) ? formalityTypes.find(element => element.value ===data.item.type).name: ''}}
            </template>

            <template #cell(actions)="data">
                <div>
                    <router-link v-show="data.item.id" :to="{ name: 'formalities_edit', params: { id: data.item.id} }">
                        <feather-icon
                            v-if="can('edit_formalities')"
                            :id="`invoice-row-${data.item.id}-send-icon`"
                            icon="EditIcon"
                            class="cursor-pointer"
                            size="16"
                        />
                    </router-link>

                    <feather-icon
                        v-if="can('delete_formality')"
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="TrashIcon"
                        size="16"
                        class="cursor-pointer mx-1 text-red"
                        @click="deleteFormality(data.item.id)"
                    />
                </div>
            </template>

        </b-table>

    </div>
</template>


<script>
import { BTable } from 'bootstrap-vue'
import {mapState} from "vuex"
import Swal from "sweetalert2"
import {formalityTypes} from "@/constants/laborFormalities"

export default {
    name: "TableFormalities",
    components: {
        BTable,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            formalityTypes,

            columns: [

                {key: 'client', label: 'Client', sortable: false},
                {key: 'type', label: 'Tipus', sortable: false},
                {key: 'actions', label: 'Accions', sortable: false},
            ]
        }
    },
    computed: {
        ...mapState('formalities', ['formalities']),
        currentRouteName() {
            return this.$route.name;
        }
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        deleteFormality(id) {
            Swal.fire({
                title: "Esteu segur d'esborrar el tràmit?",
                text: "Un cop esborrat no ho podràs recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, esborra'l!",
                cancelButtonText: "Cancel·lar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('formalities/deleteFormality', {id: id})
                        .then(response => {
                            this.showLoading = false
                            this.$router.go()
                        })
                        .catch(error => {
                            this.showLoading = false
                            console.log(error)
                        })
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>