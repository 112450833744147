<template>
    <div>
        <b-row>
            <b-col cols="8">
                <div class="mb-2">
                    <h4>Filtre</h4>
                </div>
            </b-col>
            <b-col lg="4" md="4" class="text-right">
                <b-button variant="primary" @click="cleanFilters">
                    Netejar filtres
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!this.$route.params.id" cols="6" lg="3" md="4" sm="6">
                <b-form-group label="Client" label-for="Client">
                    <b-form-input v-model="searchFields.client" placeholder="Client" autocomplete="off"
                                  @update="searchFormalities()"/>
                </b-form-group>
            </b-col>

            <b-col lg="3" md="12">
                <b-form-group label="Tipus" label-for="Tipus">
                    <select v-model="searchFields.type" class="custom-select" @change="searchFormalities()">
                        <option value=""></option>
                        <option v-for="type in this.formalityTypes"
                                :key="type.value"
                                :value="type.value"> {{ type.name }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
    BFormInput,
    BOverlay,
    BFormGroup,
    BIcon,
    BInputGroupText,
} from 'bootstrap-vue'
import vSelect from "vue-select"
import TableFormalities from "@/views/labor/formalities/list/components/tableFormalities"
import CreateFormalityButton from "@/views/labor/formalities/list/components/createFormalityButton"
import {createHelpers} from "vuex-map-fields"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import flatPickr from "vue-flatpickr-component"
import {formalityTypes} from "@/constants/laborFormalities"

const {mapFields} = createHelpers({
    getterType: 'formalities/getField',
    mutationType: 'formalities/updateField',
})

export default {
    name: "FilterFormalities",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BBadge,
        BButton,
        BTooltip,
        BPagination,
        BFormInput,
        BOverlay,
        BFormGroup,
        BIcon,
        BInputGroupText,

        vSelect,
        flatPickr,

        TableFormalities,
        CreateFormalityButton

    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            showLoading: false,
            searchFields: [],
            searchConfig: [],

            formalityTypes,
            CatalanLocale
        }
    },
    mounted() {
        this.$store.commit('formalities/RESET_STATE')
        this.getFormalities()
    },
    methods: {
        searchFormalities() {
            this.getFormalities()
        },
        cleanFilters() {
            this.searchFields = []
            this.getFormalities()
        },
        getFormalities() {
            this.showLoading = true
            this.searchConfig = [
                {'type': 'string', 'column': 'formalities.type', 'searchTerm': this.searchFields.type ?? ''},
                {
                    'type': 'concat_strings',
                    'concat_fields': ['clients.name', 'clients.first_surname', 'clients.second_surname'],
                    'searchTerm': this.searchFields.client ?? ''
                },

            ]

            this.$store.dispatch('formalities/searchFormalities', {
                client_id: this.$route.params.id ?? '',
                per_page: this.pageLength,
                page: this.page,
                filter: this.searchConfig.length > 0 ? this.searchConfig : '',
                relations: ['client']
            })
            .then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records
                this.$emit('update',this.totalRecords)
            })
            .catch(error => {
                this.showLoading = false
                console.log(error)
            })
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.background-in_progress {
    color: #ffc107;
    border: 1px solid #ffc107;
}

.background-accepted {
    color: #28c76f;
    border: 1px solid #28c76f;
}

.background-sent_to_client {
    color: #00cfe8;
    border: 1px solid #00cfe8;
}

.background-anulled {
    color: #82868b;
    border: 1px solid #82868b;
}

.background-denied {
    color: #ea5455;
    border: 1px solid #ea5455;
}

select option[value="in_progress"] {
    color: #ffc107 !important;
    background: rgba(255, 159, 67, 0.12);
}

select option[value="accepted"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}

select option[value="sent_to_client"] {
    color: #00cfe8 !important;
    background: rgba(0, 207, 232, 0.12);
}

select option[value="anulled"] {
    color: #82868b !important;
    background: rgba(130, 134, 139, 0.12);
}

select option[value="denied"] {
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.12);
}
</style>